import ClientServicesItem from '../ClientServicesItem/ClientServicesItem'
import './ClientServices.css'


export default function ClientServices({client, updateClientPage, services}) {


  return (
    <div className='ClientServices'>
        {
            services.map((service, idx) => (
                <ClientServicesItem 
                    service={service} 
                    key={idx}
                    client={client}
                    selectedForClient={client.clientServices.includes(service.code) ? true : false}
                    updateClientPage={updateClientPage}
                />
            ))
        }
    </div>
  )
}
