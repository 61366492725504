import { useState } from 'react'
import './NewProjectForm.css'

import { generateProject } from '../../utilities/api/allForms'

// import { useNavigate } from 'react-router-dom'
// import NewProjectFeaturesFormItem from '../NewProjectFeaturesFormItem/NewProjectFeaturesFormItem'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'



export default function NewProjectForm({form, existingProjectNumbers, users}) {
  const defaultState = {
    analyst: '',
  }
  const [disableSubmission, setDisableSubmission] = useState(false)
  const [formData, setFormData] = useState(defaultState)
  const disabled = !formData.analyst

  const handleChange = (evt) => {
    setFormData({...formData, [evt.target.name]: evt.target.value})
  }

  // console.log(formData)
  // console.log(form)
  // console.log(existingProjectNumbers)

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setDisableSubmission(true)
    try {
      await generateProject(form._id, formData)
      formNotificationStyle2('projectCreationNotification', 'Project created successfully', '#0A7637', 0.8)
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } catch (error) {
      formNotificationStyle2('projectCreationNotification', 'Error Creating Project!', '#CA150C', 0.8 )
      console.log(error)
      setDisableSubmission(false)
    }
  }

  return (
    <div className="NewProjectForm modal-dialog modal-dialog-centered">
      <div className="modal-content formContainer">
        <div className='header'>
          <div className='left'>
            <div className='first'>
              <svg width="35" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_207_4032)">
                <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
                <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                </g>
                <path d="M19.808 27.9404C21.0091 27.9404 21.979 28.9106 21.979 30.1355V34.056C21.979 35.271 21.0076 36.25 19.808 36.25H15.921C14.731 36.25 13.75 35.269 13.75 34.056V30.1355C13.75 28.9125 14.7294 27.9404 15.921 27.9404H19.808ZM33.079 27.9404C34.2706 27.9404 35.25 28.9125 35.25 30.1355V34.056C35.25 35.269 34.269 36.25 33.079 36.25H29.192C27.9924 36.25 27.021 35.271 27.021 34.056V30.1355C27.021 28.9106 27.9909 27.9404 29.192 27.9404H33.079ZM19.808 14.75C21.0074 14.75 21.979 15.7288 21.979 16.9452V20.8656C21.979 22.0902 21.0095 23.0596 19.808 23.0596H15.921C14.729 23.0596 13.75 22.0883 13.75 20.8656V16.9452C13.75 15.7308 14.7311 14.75 15.921 14.75H19.808ZM33.079 14.75C34.2689 14.75 35.25 15.7308 35.25 16.9452V20.8656C35.25 22.0883 34.271 23.0596 33.079 23.0596H29.192C27.9905 23.0596 27.021 22.0902 27.021 20.8656V16.9452C27.021 15.7288 27.9926 14.75 29.192 14.75H33.079Z" fill="#344054" stroke="#344054" strokeWidth="1.5"/>
              </svg>
              &nbsp;&nbsp;
            </div>
            <div className='second'>
              <div className='sec1'>
                Create New Project
              </div>
              <div className='sec2 tertiaryText'>
                Let's create a project
              </div>
            </div>
          </div>
          <div className='right'>
            <svg data-bs-dismiss="modal" width="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
        { existingProjectNumbers.includes(form.projectNumber) ?
        <div className='formContent'>
          <div className='form-group'>
            <div className='alert errorText text-center fw-bold fs-5'>
              Project already exists for this form
            </div>
          </div>
        </div>
        :
        <>
        <form id='newProjectForm' onSubmit={handleSubmit}>
          <div className='formContent'>
            <div className='form-group'>
              <label className='form-label' htmlFor="analyst">Select Analyst to Assign Project</label>
              <select className='form-select' id='analyst' name='analyst' onChange={handleChange} value={formData.analyst}>
                <option value=''>Select Analyst</option>
                {users.map((user, idx) => (
                  <option key={idx} value={user._id}>{capitalizeFirstLetter(user.firstName)} {capitalizeFirstLetter(user.lastName)}</option>
                ))}
              </select>
            </div>
          </div>
          
        </form>
        <div id='projectCreationNotification'className='projectCreationNotification text-center'></div>
        <div className='d-flex justify-content-center formButtonContainer'>
          <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
          <button className="primary submit" form='newProjectForm' type="submit" disabled={disabled || disableSubmission}>Generate Project</button>
        </div>
        </>
        }
      </div>
    </div>
  )
}
