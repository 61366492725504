import { useEffect, useState } from 'react'
import './IntakeFormNotes.css'
import { updateNotes } from '../../utilities/api/allForms'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'

export default function IntakeFormNotes({formID, notes, updateIntakeForm}) {
  const [changesMade, setChangesMade] = useState(false)
  const [formData, setFormData] = useState({
    formID: formID,
    note: notes,
  })
  // console.log(notes)
  // console.log(formData.note)

  const handleFormNoteChange = (evt) => {
    setChangesMade(true)
    if (document.getElementById(`noteSaveButton`)) {
      document.getElementById(`noteSaveButton`).disabled = false
    }
    const newFormData = {
      ...formData,
      [evt.target.name]: evt.target.value,
    };
    setFormData(newFormData);
  }

  const handleDropdownClick = (evt) => {
    // prevent dropdown from closing when clicked
    evt.stopPropagation()
  }

  const disableButton = async (buttonId) => {
    if (document.getElementById(buttonId)) {
      document.getElementById(buttonId).disabled = true
    }
  }

  const updateNote = async (evt) => {
    evt.preventDefault()
    if (!formData.note) {
      return
    }
    // console.log(formData)
    try {
      const response = await updateNotes(formData)
      // console.log(response.form)
      updateIntakeForm(response.form)
      formNotificationStyle2(`notesUpdateNotification`, `&nbsp;&nbsp;&nbsp;<span>&#x2714;</span>`, '#158444', '0.7')
      await disableButton(`noteSaveButton`)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    setFormData({
      formID: formID,
      note: notes,
    })
    // eslint-disable-next-line
  }, [notes])

  return (
    <div onClick={handleDropdownClick} className="IntakeFormNotes dropdown-menu notesDropDown" aria-labelledby={`notesDropDownMenu`}>
      <div className='noteHeader'>
        <svg width="40" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_296_4756)">
            <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
            <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
          </g>
          <rect x="12" y="17" width="26" height="3.25" rx="1.625" fill="#192434"/>
          <rect x="15.25" y="23.5" width="19.5" height="3.25" rx="1.625" fill="#192434"/>
          <rect x="20.668" y="31.083" width="8.66667" height="3.25" rx="1.625" fill="#192434"/>
        </svg>
        <span>
          &nbsp;&nbsp;&nbsp;Notes
        </span>
      </div>
      <div className='noteContainer'>
        <div className='noteBody'>
          <textarea onChange={handleFormNoteChange} name={`note`} placeholder={`Add note for this form...`} value={formData.note}></textarea>
        </div>
      </div>
      <div className='noteActionButtons'>
        { changesMade &&
        <>
        <button onClick={updateNote} className={`noteSaveButton`} id={`noteSaveButton`}>
          <span>Save</span>
        </button>
        <div id='notesUpdateNotification'className='notesUpdateNotification'></div>
        </>
        }
      </div>
      
    </div>
  )
}
