import './LoginForm.css'
import { useState } from 'react';
import { login } from '../../utilities/services/users'
// import { useNavigate } from 'react-router-dom';

const defaultState = {
    email: '',
    password: '',
    error: ''
}

export default function LoginForm({ updateUser }) {
    const [formData, setFormData] = useState(defaultState)
    // const navigate = useNavigate()

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const data = {
            email: formData.email,
            password: formData.password
        }
        try {
            const user = await login(data)
            updateUser(user)
            // redirectEndpoint ? navigate(`/${redirectEndpoint}`) : navigate('/')
        } catch (err) {
            setFormData({
                ...formData,
                // error: 'Log in Failed - Try again!'
                error: err.message
            })
        }
    }

    function handleChange(evt) {
        const newFormData = {
            ...formData,
            [evt.target.name]: evt.target.value,
            error: ''
        };
        setFormData(newFormData);
    }

    const disabled = !formData.email || !formData.password

    return (
        <div className='LoginForm'>
            <div className="formContainer">
                <div className='headerImage'><img src="https://project-intake-assets.s3.ca-central-1.amazonaws.com/Logo.svg" alt="Not Found"/></div>
                <div className="headerText1">Client Intake Login</div>
                <p className="headerText2 tertiaryText">Welcome back! Please enter your details.</p>
                <form onSubmit={handleSubmit} autoComplete="off" id='loginForm'>
                    <div className='inputContainer'>
                        <input className='form-control shadow-none firstInput' type="text" name="email" id="login-email" placeholder='Enter User ID' value={formData.email} onChange={handleChange} required autoComplete="off" />
                        <input className='form-control shadow-none secondInput' type="password" name="password" id="login-password" placeholder='Enter Password' value={formData.password} onChange={handleChange} required autoComplete="off" />
                    </div>
                    <button className='submitButton' type="submit" disabled={disabled}>Log In</button>
                </form>
            </div>
            {formData.error && <p className="errorText">&nbsp;{formData.error}</p>}
            <footer className='footer secondaryText'>
                <p className='tertiaryText'>© PatAnalysis 2024</p>
            </footer>
        </div>
    )
}