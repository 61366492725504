import './IntakeForm5Review.css'

export default function IntakeForm5Review({form, services}) {
    const fallback = 'No information provided'

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

  return (
    <div className='IntakeForm5Review intakeFormReviewGlobal'>
        <div className='projectNumber'>
            {`${form.projectNumber}`}
        </div>
        <div className='service'>
            {`${services.find(service => service.code === form.service).name}`}
        </div>
        {/* <div className='titleOfInvention'>
            <span>Title: </span>
            <span>{`${form.title}`}</span>
        </div> */}
        <div className='submissionDate'>
            <span>Submission Date: </span>
            <span>{`${form.submissionDate? form.submissionDate.split('T')[0] : form.submissionDate}`}</span>
        </div>
        <div className='approvalDate'>
            { form.status === 'complete' &&
            <>
            <span>Approval Date: </span>
            <span>{form.approvalDate.split('T')[0] || 'N/A'}</span>
            </>
            }
        </div>
        <div className='generalInformation'>
            <div className='sectionTitle'>
                1. General Information
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    Legal name of Client
                </div>
                <div className='answer'>
                    {form.companyName}
                </div>
                <div className='questionL1'>
                    Legal address of Client
                </div>
                <div className='answer'>
                    {form.companyAddress}
                </div>
                <div className='questionL1'>
                    Client Representative(s)
                </div>
                <div className='answer'>
                    {convertToString(form.companyRepresentative)}
                </div>
            </div>
        </div>
        <div className='technical section'>
            <div className='sectionTitle'>
                2. Technical Section
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    2.1 Brief Description of Technology or Product
                </div>
                <div className='answer'>
                    {form.descriptionOfTech || fallback}
                </div>
                <div className='questionL1'>
                    2.2 Known Keywords
                </div>
                <div className='answer'>
                    {form.knownKeywords || fallback}
                </div>
                <div className='questionL1'>
                    2.3 Known Competitors
                </div>
                <div className='answer'>
                    {form.knownCompetitors || fallback}
                </div>
                <div className='questionL1'>
                    2.6 Additional Documents
                </div>
                <div className='answer'>
                    { (form.fileUploads[0]!=='' && form.fileUploads.length>0) ?
                    <>
                        <div>Links to additional documents uploaded by the client are provided below:</div>
                        <ul>
                            {form.fileUploads.map((file, idx) => (
                                <li key={idx}><a target='_blank' rel="noreferrer" href={file}>{file}</a></li>
                            ))}
                        </ul>
                    </>
                    :
                    'No additional documents provided'
                    }
                </div>
            </div>
        </div>

    </div>
  )
}
