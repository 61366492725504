import { useEffect, useState } from 'react';
import './ClientList.css';
import { getAllClients } from '../../utilities/api/clients';
import ClientListItem from '../../components/ClientListItem/ClientListItem';
import NewClient from '../../components/NewClient/NewClient';

export default function ClientList({ user}) {
    const [loading, setLoading] = useState(true)
    const [clients, setClients] = useState([])
    const [sortedClients, setSortedClients] = useState([])
    const [sortOrder, setSortOrder] = useState('desc')
    const [sortHeader, setSortHeader] = useState('createdAt')

    const getClients = async () => {
        setLoading(true)
        try {
            const data = await getAllClients()
            data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            setClients(data)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleHeaderClick = (evt) => {
        const header = evt.target.id
        setSortHeader(header)
        let sortedClients = [...clients]
        if (sortOrder === 'asc') {
            sortedClients.sort((a, b) => (typeof(a[header])==='string' ? a[header].toLowerCase() : a[header]) < (typeof(b[header])==='string' ? b[header].toLowerCase() : b[header]) ? 1 : -1)
            setSortOrder('desc')
        } else {
            sortedClients.sort((a, b) => (typeof(a[header])==='string' ? a[header].toLowerCase() : a[header]) > (typeof(b[header])==='string' ? b[header].toLowerCase() : b[header]) ? 1 : -1)
            setSortOrder('asc')
        }
        setSortedClients(sortedClients)
    }

    useEffect(() => {
        getClients()
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        setSortedClients(clients)
    },[clients])

  return (
    <div className='ClientList'>
        { loading ? 
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        <div className='contentHeader'>
            <div className='title'>Client List</div>
            <div className='actionButtons'>
                <button className='newClientButton' title='Add New Client' data-bs-toggle="modal" data-bs-target={`#newClientModal`}>
                    <svg width="25" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="38" height="38" rx="10" fill="#1A5E9B"/>
                        <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
                        <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    &nbsp;
                    New Client
                </button>
                <NewClient user={user} />
            </div>
        </div>
        <div className='clientCount'>Client Count: <span>{clients.length}</span></div>
        <div className='clientTable'>
            <table className='table table-hover'>
                <thead className=''>
                    <tr>
                        <th id='companyName' onClick={handleHeaderClick} title='Click to sort by client name'>
                            <div className='tableHeaderWithArrow'><div>Client Name&nbsp;&nbsp;</div>
                            { sortHeader==='companyName'?
                                <>
                                {sortOrder==='asc' ?
                                <div>
                                    <div className='sortIndicatorActive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                                : 
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorActive'>&#9660;</div>
                                </div>
                                }
                                </>
                                :
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                            }
                            </div>
                        </th>
                        <th id='clientID' onClick={handleHeaderClick} title='Click to sort by client ID'>
                            <div className='tableHeaderWithArrow'><div>Client ID&nbsp;&nbsp;</div>
                            { sortHeader==='clientID'?
                                <>
                                {sortOrder==='asc' ?
                                <div>
                                    <div className='sortIndicatorActive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                                : 
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorActive'>&#9660;</div>
                                </div>
                                }
                                </>
                                :
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                            }
                            </div>
                        </th>
                        <th id='companyEmail' onClick={handleHeaderClick} title='Click to sort by email'>
                            <div className='tableHeaderWithArrow'><div>Company Email&nbsp;&nbsp;</div>
                            { sortHeader==='companyEmail'?
                                <>
                                {sortOrder==='asc' ?
                                <div>
                                    <div className='sortIndicatorActive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                                : 
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorActive'>&#9660;</div>
                                </div>
                                }
                                </>
                                :
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                            }
                            </div>
                        </th>
                        <th id='companyPhoneNumber' onClick={handleHeaderClick} title='Click to sort by phone number'>
                            <div className='tableHeaderWithArrow'><div>Company Phone Number&nbsp;&nbsp;</div>
                            { sortHeader==='companyPhoneNumber'?
                                <>
                                {sortOrder==='asc' ?
                                <div>
                                    <div className='sortIndicatorActive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                                : 
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorActive'>&#9660;</div>
                                </div>
                                }
                                </>
                                :
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                            }
                            </div>
                        </th>
                        <th id='createdAt' onClick={handleHeaderClick} title='Click to sort by creation date'>
                            <div className='tableHeaderWithArrow'><div>Creation Date&nbsp;&nbsp;</div>
                            { sortHeader==='createdAt'?
                                <>
                                {sortOrder==='asc' ?
                                <div>
                                    <div className='sortIndicatorActive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                                : 
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorActive'>&#9660;</div>
                                </div>
                                }
                                </>
                                :
                                <div>
                                    <div className='sortIndicatorInactive'>&#9650;</div>
                                    <div className='sortIndicatorInactive'>&#9660;</div>
                                </div>
                            }
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { sortedClients.length === 0 ? 
                    <tr>
                        <td colSpan='5' className='text-center'>No Clients</td>
                    </tr>
                    :
                    sortedClients.map((client, idx) => (
                        <ClientListItem client={client} key={idx} />
                    ))
                    }
                </tbody>
            </table>
        </div>
        
        </>
        }
    </div>
  )
}
