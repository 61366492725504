import {
    Document, TableRow, TableCell, Table, Paragraph, VerticalAlign, AlignmentType, WidthType,
    Header, TextRun, PageNumber, Footer, HeadingLevel, ExternalHyperlink, Packer, convertInchesToTwip,
} from 'docx'

import {saveAs} from 'file-saver'
// import { capitalizeFirstLetter } from './capitalizeFirstLetter'

const convertToString = (array) => {
    // convert array to string separated by commas and spaces
    let string = ''
    array.forEach((item, idx) => {
        if (idx === array.length - 1) {
            string += item
        } else {
            string += item + ', '
        }
    })
    return string
}

const emptyFieldText = 'No information provided.'


const generalInformation = async (form, services) => {
    let elements = []
    let serviceName
    services.forEach(service => {
        if (service.code === form.service) {
            serviceName = service.name
        }
    })

    const service = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
        spacing: { before: 200, after: 500 },
        children: [
            new TextRun({
                text: `${serviceName}`,
                allCaps: true,
                italics: false,
            }),
        ]
    })
    elements.push(service)

    const submissionDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 200, after: 200 },
        children: [
            new TextRun({
                text: `Submission Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.submissionDate? form.submissionDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(submissionDate)

    const approvalDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 100, after: 500 },
        children: [
            new TextRun({
                text: `Approval Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.approvalDate? form.approvalDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(approvalDate)

    const firstHeader1 = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "1 General Information",
                allCaps: true,
            })
        ]
    })
    elements.push(firstHeader1)

    // Identification
    const firstHeader2 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "Identification",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstHeader2)

    const identificationTableRows = []

    const identificationTableFirstTableRow = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 1000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal name of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyName}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableFirstTableRow)

    const identificationTableSecondTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal address of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyAddress}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableSecondTableRow)

    const identificationTableThirdTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        //     fill: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Full name of company’s representative(s)`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${convertToString(form.companyRepresentative)}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableThirdTableRow)

    const identificationTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: identificationTableRows
    })
    elements.push(identificationTable)

    return elements
}

const technicalSection = (form) => {
    let elements = []
    const mainHeader = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        spacing: { after: 10},
        children: [
            new TextRun({
                text: "2 TECHNICAL SECTION",
                allCaps: true,
            })
        ]
    })
    elements.push(mainHeader)

    // 2.1 Brief Description of Technology or Product
    const sec2p1Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 100},
        children: [
            new TextRun({
                text: "2.1 Brief Description of Technology or Product",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p1Header1)

    const sec2p1Statement = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { before: 100, after: 250 },
        children: [
            new TextRun({
                text: `${form.descriptionOfTech? form.descriptionOfTech : emptyFieldText}`,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p1Statement)

    // 2.2 Known Keywords
    const sec2p2Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "2.2 Known Keywords",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p2Header1)

    const sec2p2Statement = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { before: 100, after: 250 },
        children: [
            new TextRun({
                text: `${form.knownKeywords? form.knownKeywords : emptyFieldText}`,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p2Statement)

    // 2.3 Known Competitors
    const sec2p3Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "2.3 Known Competitors",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p3Header1)

    const sec2p3Statement = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { before: 200, after: 400 },
        children: [
            new TextRun({
                text: `${form.knownCompetitors? form.knownCompetitors : emptyFieldText}`,
            })
        ]
    })
    elements.push(sec2p3Statement)

    // 2.4 Additional Documents
    const sec2p4Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "2.4 Additional Documents",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p4Header1)

    if (form.fileUploads.length>0 && form.fileUploads[0] !== '') {
        const sec2p4Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 250 },
            children: [
                new TextRun({
                    text: `Links to additional documents uploaded by the client are provided below:`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p4Statement)
        
        form.fileUploads.forEach(fileURL => {
            const additionalDocumentParagraph = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 200, after: 200 },
                bullet: {
                    level: 0
                },
                children: [
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${fileURL}`,
                                style: "Hyperlink",
                                size: 24,
                                font: 'arial',
                            }),
                        ],
                        link: `${fileURL}`,
                    }),
                ]
            })
            elements.push(additionalDocumentParagraph)
        });
    } else {
        const sec2p3Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 250 },
            children: [
                new TextRun({
                    text: emptyFieldText,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p3Statement)
    }

    return elements
}


async function saveDocumentToFile(doc, fileName, mimeType) {
    // Create a mime type that will associate the new file with Microsoft Word
    // const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then(blob => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
}

export const generateIntakeFormDocE = async (form, services) => {
    // console.log(data)
    const doc = new Document({
        creator: "Riahi-IP",
        description: `${form.projectNumber} - Intake Form`,
        title: `${form.titleOfInvention}`,
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        bold: true,
                        italics: false,
                        color: "000000",
                        font: "arial",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                        font: "arial",
                        // underline: {
                        //     type: UnderlineType.DOUBLE,
                        //     color: "000000",
                        // },
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                        font: "arial",
                    },
                },
                listParagraph: {
                    run: {
                        color: "000000",
                        font: "arial",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                    name: "My Spectacular Style",
                    basedOn: "Heading1",
                    next: "Heading1",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "990000",
                        font: "arial",
                        size: 24,
                    },
                },
                {
                    id: "MyNormalStyle",
                    name: "My Normal Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyTableStyle",
                    name: "My Table Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 20,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyItalicsStyle",
                    name: "My Italics Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                // 1 GENERAL INFORMATION
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: [PageNumber.CURRENT],
                                        font: 'arial',
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                spacing: {after: 400 },
                                children: [
                                    new TextRun({
                                        text: `${form.projectNumber}`,
                                        allCaps: true,
                                        font: "arial",
                                        size: 24,
                                        bold: false,
                                    }),
                                ]
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                border: {
                                    top: {
                                        color: 'auto',
                                        space: 1,
                                        style: "single",
                                        size: 10,
                                    }
                                },
                                children: [
                                    new TextRun({
                                        text: "Riahi Patents, Inc.",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new TextRun({
                                        text: "                                                                    ",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new ExternalHyperlink({
                                        children: [
                                            new TextRun({
                                                text: "https://riahipatents.com",
                                                style: "Hyperlink",
                                                size: 24,
                                                font: 'arial',
                                            }),
                                        ],
                                        link: "https://riahipatents.com",
                                    }),
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Confidential Document",
                                        size: 24,
                                        font: 'arial',
                                        italics: true,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                           
                        ],
                    }),
                },
                children: await generalInformation(form, services)
            },
            {
                // 2 TECHNICAL INTAKE FORM
                // properties: {
                //     type: SectionType.CONTINUOUS,
                // },
                children: technicalSection(form)
            },
        ],
    });
    const docTitle = (`${form.projectNumber.replaceAll('-','.')} - Intake Form (${form.service}).docx`)
    await saveDocumentToFile(doc, docTitle, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")

    // return Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(`${data.project.projNum}.docx`, buffer);
    // });
}