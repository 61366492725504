import { useEffect, useState } from 'react'
import './ClientDetails.css'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteClient, getClient, sendClientCredentaials } from '../../utilities/api/clients'
import { getServices } from '../../utilities/api/services'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'
import ClientServices from '../../components/ClientServices/ClientServices'
import FormGeneration from '../../components/FormGeneration/FormGeneration'
import FormList from '../../components/FormList/FormList'
import { formNotificationStyle1 } from '../../utilities/services/formNotification'
import UpdateClient from '../../components/UpdateClient/UpdateClient'
import { getIntakeForms } from '../../utilities/api/allForms'

export default function ClientDetails({ user}) {
    const [receiverEmail, setReceiverEmail] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingIntakeForms, setLoadingIntakeForms] = useState(true)
    const [existingIntakeForms, setExistingIntakeForms] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const [client, setClient] = useState(null)
    const [allServices, setAllServices] = useState([]) // Array of all services
    const [services, setServices] = useState([]) // Array of services after filteration
    const [fullClientServices, setFullClientServices] = useState([])// Full service objects for client services

    const getCurrentClient = async (clientID) => {
        setLoading(true)
        try {
            const clientData = await getClient(clientID)
            const services = await getServices()
            services.sort((a, b) => a.name > b.name ? 1 : -1)
            // console.log(services)
            setAllServices(services)
            // remove CTAF, CTS, USTS, and USTAF from services since they have been replaced by TS and TAF
            const filteredServices = services.filter(service => !['CTAF', 'CTS', 'USTS', 'USTAF'].includes(service.code))
            // console.log(filteredServices)
            getFullClientServices(clientData, filteredServices)
            setClient(clientData)
            setServices(filteredServices)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const updateIntakeForms = async (id, loadingSpinner) => {
        loadingSpinner = loadingSpinner || 'showSpinner'
        if (loadingSpinner !== 'hideSpinner' && loadingSpinner === 'showSpinner'){
            setLoadingIntakeForms(true)
        }
        try {
            const forms = await getIntakeForms(id)
            setExistingIntakeForms(forms)
        } catch (error) {
            console.log(error)
        }
        setLoadingIntakeForms(false)
    }

    const updateClientPage = (clientInfo) => {
        setClient(clientInfo)
    }

    const getFullClientServices = (clientInfo, serviceListInfo) => {
        clientInfo = clientInfo || client
        const serviceList = serviceListInfo || services
        if (!clientInfo) return
        const clientServices = []
        serviceList.forEach(service => {
            if (clientInfo.clientServices.includes(service.code)) {
                clientServices.push(service)
            }
        })
        setFullClientServices(clientServices)
    }

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const deleteCurrentClient = async () => {
        try {
            await deleteClient(user._id, client._id)
            navigate('/')
        } catch (error) {
            console.log(error)
        }
    }

    const sendLoginCredentials = async (evt) => {
        evt.preventDefault()
        console.log(receiverEmail)
        try {
            await sendClientCredentaials(client._id, {email: receiverEmail.trim()})
            formNotificationStyle1('sentCredentialsNotification', 'Sent!', 'green', 0.9)
        } catch (error) {
            console.log(error)
            formNotificationStyle1('sentCredentialsNotification', 'Error!', 'red', 0.9)
        }
    }

    const updateReceiverEmail = (evt) => {
        setReceiverEmail(evt.target.value)
    }

    // console.log(client)

    useEffect(() => {
        getCurrentClient(params.clientID)
        // eslint-disable-next-line
    },[])

        
  return (
    <div className='ClientDetails'>
        { loading ?
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        { client ?
            <>
            <div className='topContainer'>
                <div className='topContainerContent1'>
                    <div className='head'>
                        <img src={require('./ClientHead.png')} alt="" />
                        <div className='floatingContent'>
                            <div className='representatives'>Representative(s): {convertToString(client.companyRepresentative)}</div>
                            <div className='deleteClient'>
                                <button className='deleteClientButton' data-bs-toggle="modal" data-bs-target={`#deleteClient`} title='delete'>
                                    <svg width="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.7366 9.3514C17.7366 9.0984 17.5312 8.89307 17.2782 8.89307H5.18195C4.92803 8.89307 4.72362 9.0984 4.72362 9.3514C4.72362 10.2653 4.70712 11.1169 4.69153 11.9061C4.6237 15.3427 4.57878 17.6133 5.96753 19.0296C6.95387 20.0351 8.57362 20.4843 11.22 20.4843C13.8692 20.4843 15.4917 20.0342 16.4807 19.0268C17.8759 17.6041 17.8319 15.3226 17.7668 11.8667C17.7522 11.0885 17.7366 10.2497 17.7366 9.3514Z" fill="#FF6041"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M18.6312 5.84696H14.8353C14.7977 5.24746 14.6318 4.4023 14.0351 3.71663C13.4044 2.99155 12.4602 2.62305 11.2282 2.62305C9.99716 2.62305 9.05304 2.99155 8.42237 3.71663C7.82562 4.4023 7.65878 5.24746 7.6212 5.84696H3.82712C3.44762 5.84696 3.13962 6.15496 3.13962 6.53446C3.13962 6.91396 3.44762 7.22196 3.82712 7.22196H18.6312C19.0107 7.22196 19.3187 6.91396 19.3187 6.53446C19.3187 6.15496 19.0107 5.84696 18.6312 5.84696Z" fill="#FF6041"/>
                                    </svg>
                                </button>
                                <div className="modal fade" id='deleteClient' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteClientHeader`} aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <svg width="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
                                                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8"/>
                                                    <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <div className="modalBodyContent" id={`deleteClientHeader`}>
                                                    <div>
                                                        Delete<span className='modalCompanyName'> {capitalizeFirstLetter(client.companyName)}</span>
                                                    </div>
                                                    <div className='deleteConfirmation tertiaryText'>
                                                        Are you sure you want to delete this client?
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                                                <button onClick={deleteCurrentClient} type="button" className="confirm" data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='floatingAvatar'>
                        <span>
                            {client.companyName.charAt(0).toUpperCase()}
                        </span>
                    </div>
                    <div className='mainContent'>
                        <div className='mainContent1'>
                            <div className='clientName'>{capitalizeFirstLetter(client.companyName)}</div>
                            <div className='section2'>
                                <div className='clientInfo1'>
                                    <div className='clientEmail'>
                                        <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3333 5.00016C18.3333 4.0835 17.5833 3.3335 16.6666 3.3335H3.33329C2.41663 3.3335 1.66663 4.0835 1.66663 5.00016M18.3333 5.00016V15.0002C18.3333 15.9168 17.5833 16.6668 16.6666 16.6668H3.33329C2.41663 16.6668 1.66663 15.9168 1.66663 15.0002V5.00016M18.3333 5.00016L9.99996 10.8335L1.66663 5.00016" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>&nbsp;{client.companyEmail}</span>
                                    </div>
                                    <span>&nbsp;</span>
                                    <div className='clientID'>Client ID:<span>&nbsp;{client.clientID}</span></div>
                                </div>
                                <div className='clientUpdate'>
                                    <button className='updateClient' title='Update Client' data-bs-toggle="modal" data-bs-target={`#updateClientModal`}>
                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z" fill="#2566A0"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Update Client</span>
                                    </button>
                                    <UpdateClient user={user} client={client} updateClientPage={updateClientPage} />
                                </div>
                            </div>
                            <div className='section3'>
                                <div className='section3Content'>
                                    <svg width="40" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="22" cy="22" r="22" fill="#1A5E9B" fillOpacity="0.09"/>
                                        <path d="M30.3333 27.0994V29.5994C30.3343 29.8315 30.2867 30.0612 30.1937 30.2739C30.1008 30.4865 29.9644 30.6774 29.7934 30.8343C29.6224 30.9912 29.4205 31.1107 29.2006 31.185C28.9808 31.2594 28.7478 31.287 28.5167 31.2661C25.9523 30.9875 23.4892 30.1112 21.325 28.7078C19.3115 27.4283 17.6044 25.7212 16.325 23.7078C14.9166 21.5338 14.0402 19.0586 13.7667 16.4828C13.7458 16.2523 13.7732 16.0201 13.8471 15.8008C13.9209 15.5815 14.0396 15.38 14.1956 15.2091C14.3516 15.0382 14.5415 14.9017 14.7531 14.8082C14.9648 14.7147 15.1936 14.6663 15.425 14.6661H17.925C18.3294 14.6621 18.7215 14.8053 19.0281 15.069C19.3348 15.3328 19.535 15.699 19.5917 16.0994C19.6972 16.8995 19.8929 17.685 20.175 18.4411C20.2871 18.7394 20.3114 19.0635 20.2449 19.3752C20.1784 19.6868 20.024 19.9729 19.8 20.1994L18.7417 21.2578C19.9279 23.3441 21.6554 25.0715 23.7417 26.2578L24.8 25.1994C25.0266 24.9754 25.3126 24.821 25.6243 24.7545C25.9359 24.688 26.26 24.7123 26.5583 24.8244C27.3144 25.1066 28.0999 25.3022 28.9 25.4078C29.3048 25.4649 29.6745 25.6688 29.9388 25.9807C30.203 26.2926 30.3435 26.6907 30.3333 27.0994Z" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div>
                                        <div className='section3ContentHeader'>Client Phone Number</div> 
                                        <div>{client.companyPhoneNumber}</div>
                                    </div>
                                </div>
                                <div className='section3Content'>
                                    <svg width="40" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="22" cy="22" r="22" fill="#1A5E9B" fillOpacity="0.09"/>
                                        <path d="M29.5 20.334C29.5 26.1673 22 31.1673 22 31.1673C22 31.1673 14.5 26.1673 14.5 20.334C14.5 18.3449 15.2902 16.4372 16.6967 15.0307C18.1032 13.6242 20.0109 12.834 22 12.834C23.9891 12.834 25.8968 13.6242 27.3033 15.0307C28.7098 16.4372 29.5 18.3449 29.5 20.334Z" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22 22.834C23.3807 22.834 24.5 21.7147 24.5 20.334C24.5 18.9533 23.3807 17.834 22 17.834C20.6193 17.834 19.5 18.9533 19.5 20.334C19.5 21.7147 20.6193 22.834 22 22.834Z" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div>
                                        <div className='section3ContentHeader'>Address</div>
                                        <div>{client.companyAddress}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mainContent2'>
                            <div className='content'>
                                <div className='title'>
                                    Login Credentials
                                </div>
                                <div>
                                    <button className='viewCredentials' title='View Client Credentials'  data-bs-toggle="modal" data-bs-target={`#viewClientCredentialsModal`}>
                                        <svg width="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z" fill="#FFFFFF"/>
                                            <path d="M20.1321 9.78633C19.0469 8.10797 17.6388 6.68227 16.0605 5.66303C14.3145 4.53428 12.3867 3.9375 10.4861 3.9375C8.74209 3.9375 7.02723 4.43584 5.38907 5.41857C3.7185 6.42059 2.20502 7.88443 0.890474 9.7691C0.742073 9.9821 0.660343 10.2344 0.655669 10.494C0.650995 10.7535 0.723588 11.0086 0.864224 11.2268C1.94745 12.922 3.34157 14.3497 4.89524 15.355C6.64456 16.4883 8.52717 17.0625 10.4861 17.0625C12.4019 17.0625 14.3338 16.4706 16.0724 15.3513C17.6499 14.3354 19.0551 12.9043 20.1362 11.212C20.272 10.9989 20.3438 10.7513 20.3431 10.4986C20.3424 10.2459 20.2691 9.99867 20.1321 9.78633ZM10.5 14.4375C9.72126 14.4375 8.95998 14.2066 8.31247 13.7739C7.66495 13.3413 7.16027 12.7263 6.86225 12.0068C6.56423 11.2873 6.48625 10.4956 6.63818 9.73183C6.79011 8.96803 7.16512 8.26644 7.71579 7.71577C8.26646 7.1651 8.96806 6.79009 9.73186 6.63816C10.4957 6.48623 11.2874 6.5642 12.0068 6.86222C12.7263 7.16024 13.3413 7.66492 13.7739 8.31244C14.2066 8.95996 14.4375 9.72124 14.4375 10.5C14.4363 11.5439 14.0211 12.5447 13.2829 13.2829C12.5448 14.0211 11.5439 14.4363 10.5 14.4375Z" fill="#FFFFFF"/>
                                        </svg>
                                        <span>&nbsp;View Credentials</span>
                                    </button>
                                    <div className='modal fade viewClientCredentialsModal' id='viewClientCredentialsModal' data-bs-keyboard="false" tabIndex="-1"  aria-labelledby={`viewClientCredentialsHeader`} aria-hidden="true">
                                        <div className=' modal-dialog modal-dialog-centered'>
                                            <div className='modal-content'>
                                                <div className='modal-header'>
                                                    <div className='headerContent'>
                                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g filter="url(#filter0_d_1210_1576)">
                                                                <rect x="2" y="1" width="48" height="48" rx="10" fill="white"/>
                                                                <rect x="2.5" y="1.5" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                                                            </g>
                                                            <path d="M32.4354 17.582C31.9352 17.0686 31.1949 16.8773 30.5046 17.0787L14.408 21.7595C13.6797 21.9619 13.1635 22.5427 13.0244 23.2806C12.8824 24.0315 13.3786 24.9848 14.0268 25.3834L19.0599 28.4768C19.5761 28.7939 20.2424 28.7144 20.6696 28.2835L26.4329 22.4843C26.723 22.1823 27.2032 22.1823 27.4934 22.4843C27.7835 22.7762 27.7835 23.2493 27.4934 23.5513L21.72 29.3516C21.2918 29.7814 21.2118 30.4508 21.5269 30.9702L24.6022 36.0538C24.9623 36.6577 25.5826 37 26.2628 37C26.3429 37 26.4329 37 26.513 36.9899C27.2933 36.8893 27.9135 36.3558 28.1436 35.6008L32.9156 19.5248C33.1257 18.8403 32.9356 18.0954 32.4354 17.582Z" fill="#344054"/>
                                                            <defs>
                                                                <filter id="filter0_d_1210_1576" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                    <feOffset dy="1"/>
                                                                    <feGaussianBlur stdDeviation="1"/>
                                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
                                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1210_1576"/>
                                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1210_1576" result="shape"/>
                                                                </filter>
                                                            </defs>
                                                        </svg>
                                                        <div>
                                                            <div className='firstHeader'>Login Credentials</div>
                                                            <div className='secondHeader'>View and Send Credentials</div>
                                                        </div>
                                                    </div>
                                                    <div className='right'>
                                                        <svg data-bs-dismiss="modal" width="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
                                                        <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className='modal-body'>
                                                    <div className='itemContainer'>
                                                        <div className='item'>
                                                            <svg width="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="22" cy="22" r="22" fill="#1A5E9B" fillOpacity="0.1"/>
                                                                <path d="M30.6667 16.6667C30.6667 15.75 29.9167 15 29 15H15.6667C14.75 15 14 15.75 14 16.6667M30.6667 16.6667V26.6667C30.6667 27.5833 29.9167 28.3333 29 28.3333H15.6667C14.75 28.3333 14 27.5833 14 26.6667V16.6667M30.6667 16.6667L22.3333 22.5L14 16.6667" stroke="#1A5E9B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <div className='itemContent'>
                                                                <div className='label'>
                                                                    User ID
                                                                </div>
                                                                {client.companyEmail}
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <svg width="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="22" cy="22" r="22" fill="#1A5E9B" fillOpacity="0.1"/>
                                                                <path d="M25.0435 20.0435V16.2199C25.0435 15.3659 24.6724 14.5469 24.012 13.9431C23.3515 13.3392 22.4558 13 21.5217 13C20.5877 13 19.6919 13.3392 19.0315 13.9431C18.371 14.5469 18 15.3659 18 16.2199V20.0435" stroke="#1A5E9B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M26.3087 20.0439H16.9957C15.8935 20.0439 15 20.9096 15 21.9774V29.067C15 30.1348 15.8935 31.0005 16.9957 31.0005H26.3087C27.4109 31.0005 28.3043 30.1348 28.3043 29.067V21.9774C28.3043 20.9096 27.4109 20.0439 26.3087 20.0439Z" stroke="#1A5E9B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <div className='itemContent'>
                                                                <div className='label'>
                                                                    Password
                                                                </div>
                                                                {client.clientPassword}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form className='sendCredentialsForm' id='sendCredentialsForm' title='Send Login Credentials to Client' onSubmit={sendLoginCredentials}>
                                                        <label htmlFor="receiverEmail">Receiver's Email</label>
                                                        <input className='form-control' type="email" name="receiverEmail" id="receiverEmail" placeholder="Enter Receiver's Email" defaultValue={receiverEmail} onChange={updateReceiverEmail}/>
                                                    </form>
                                                </div>
                                                <div className='modal-footer'>
                                                    <div className='modalButtonContainer'>
                                                        <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
                                                        <button data-bs-dismiss="modal" className="primary sendCredentialsButton" type="submit" form='sendCredentialsForm'>Send Credentials</button>
                                                    </div>
                                                    <div className='ms-3' id='sentCredentialsNotification'></div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                    
                </div>
                <div className='topContainerContent2'>
                    <div className="nav nav-tabs flex-row navigation" id="v-tab" role='tablist' aria-orientation="horizontal">
                        <button className="nav-link active" id='servicesTab' data-bs-toggle="tab" role="tab" aria-controls="services" aria-selected="true" data-bs-target="#services" type="button">
                            Select Services
                        </button>
                        <button className="nav-link" id='formGenerationTab' data-bs-toggle="tab" role="tab" aria-controls="formGeneration" aria-selected="false" data-bs-target="#formGeneration" type="button">
                            Generate Forms
                        </button>
                    </div>
                    <div className='content tab-content' id="v-tabContent">
                        <div className="tab-pane fade show active" id="services" role="tabpanel" aria-labelledby="servicesTab">
                            <ClientServices client={client} updateClientPage={updateClientPage} services={services} />
                        </div>
                        <div className="tab-pane fade" id="formGeneration" role="tabpanel" aria-labelledby="formGenerationTab">
                            <FormGeneration client={client} updateClientPage={updateClientPage} updateIntakeForms={updateIntakeForms} fullClientServices={fullClientServices} getFullClientServices={getFullClientServices} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottomContainer'>
                <FormList client={client} updateIntakeForms={updateIntakeForms} loadingIntakeForms={loadingIntakeForms} existingIntakeForms={existingIntakeForms} services={allServices} />
            </div>
            </>
            :
            <div className='card'>
                <div className='card-body'>
                    <h5 className='card-title'>Client Not Found</h5>
                </div>
            </div>
        }
        </>
        }
        
    </div>
  )
}
