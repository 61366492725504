import { sendRequest } from "../request";
const BASE_URL = '/api/clients';

export function createClient(clientData){
    return sendRequest(`${BASE_URL}/newClient`, "POST", clientData, 'Unable to create client!')
}

export function getAllClients(){
    return sendRequest(BASE_URL, "GET", null, 'Unable to retrieve clients!')
}

export function getClientIDs(){
    return sendRequest(`${BASE_URL}/clientIDs`, "GET", null, 'Unable to retrieve client IDs!')
}

export function getClient(clientID){
    return sendRequest(`${BASE_URL}/${clientID}`, "GET", null, 'Unable to retrieve client!')
}

export function updateClientServices(clientID, serviceData){
    return sendRequest(`${BASE_URL}/${clientID}/updateClientService`, "PUT", serviceData, 'Unable to update service status!')
}

export function updateClient(clientID, clientData){
    return sendRequest(`${BASE_URL}/${clientID}/updateClient`, "PUT", clientData, 'Unable to update client!')
}

export function deleteClient(userID, clientID){
    return sendRequest(`${BASE_URL}/${clientID}/${userID}`, "DELETE", null, 'Unable to delete client!')
}

export function sendClientCredentaials(clientID, data) {
    return sendRequest(`${BASE_URL}/${clientID}/sendCredentials`, "POST", data, 'Unable to send client credentials!')
}
