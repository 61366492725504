import './IntakeForm3Review.css'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'

export default function IntakeForm3Review({form, services}) {
    const fallback = 'No information provided'

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

  return (
    <div className='IntakeForm3Review intakeFormReviewGlobal'>
        <div className='projectNumber'>
            {`${form.projectNumber}`}
        </div>
        <div className='service'>
            {`${services.find(service => service.code === form.service).name}`}
        </div>
        <div className='titleOfInvention'>
            <span>Title: </span>
            <span>{`${form.title}`}</span>
        </div>
        <div className='submissionDate'>
            <span>Submission Date: </span>
            <span>{`${form.submissionDate? form.submissionDate.split('T')[0] : form.submissionDate}`}</span>
        </div>
        <div className='approvalDate'>
            { form.status === 'complete' &&
            <>
            <span>Approval Date: </span>
            <span>{form.approvalDate.split('T')[0] || 'N/A'}</span>
            </>
            }
        </div>
        <div className='generalInformation'>
            <div className='sectionTitle'>
                1. General Information
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    Legal name of Client
                </div>
                <div className='answer'>
                    {form.companyName}
                </div>
                <div className='questionL1'>
                    Legal address of Client
                </div>
                <div className='answer'>
                    {form.companyAddress}
                </div>
                <div className='questionL1'>
                    Client Representative(s)
                </div>
                <div className='answer'>
                    {convertToString(form.companyRepresentative)}
                </div>
            </div>
        </div>
        <div className='technical section'>
            <div className='sectionTitle'>
                2. Technical Section
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    2.1 Trademark Name
                </div>
                <div className='answer'>
                    {form.title || fallback}
                </div>
                <div className='questionL1'>
                    2.2 Industry (or detailed description of goods and/or services)
                </div>
                <div className='answer'>
                    {form.industry || fallback}
                </div>
                <div className='questionL1'>
                    2.3 Trademark Type
                </div>
                <div className='answer'>
                    { form.trademarkType === 'design' ?
                    'Design (Logo)'
                    :
                    capitalizeFirstLetter(form.trademarkType) || fallback
                    }
                </div>
                <div className='questionL1'>
                    2.4 Country of Interest
                </div>
                <div className='answer'>
                    {form.countryOfInterest || fallback}
                </div>
                <div className='questionL1'>
                    2.5 Trademark currently in use?
                </div>
                <div className='answer'>
                    {form.currentlyUsed==='Y' ? 'YES' : 'NO'}
                </div>
                { form.currentlyUsed==='Y' &&
                <div className='answer'>
                    <div><strong>The date of first use in commerce is: </strong></div>
                    <ul>
                        <li>{form.currentlyUsedDescription1 || fallback}</li>
                    </ul>
                </div>
                }
                <div className='questionL1'>
                    2.6 Additional Documents
                </div>
                <div className='answer'>
                    { (form.fileUploads[0]!=='' && form.fileUploads.length>0) ?
                    <>
                        <div>Links to additional documents uploaded by the client are provided below:</div>
                        <ul>
                            {form.fileUploads.map((file, idx) => (
                                <li key={idx}><a target='_blank' rel="noreferrer" href={file}>{file}</a></li>
                            ))}
                        </ul>
                    </>
                    :
                    'No additional documents provided'
                    }
                </div>
            </div>
        </div>

    </div>
  )
}
