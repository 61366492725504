import { sendRequest } from "../request";
const BASE_URL = '/api/allForms';

export function create(clientID, data) {
    return sendRequest(`${BASE_URL}/${clientID}`, "POST", data, "Unable to create form(s)");
}

export function getIntakeForms(clientID) {
    return sendRequest(`${BASE_URL}/${clientID}/intakeForms`)
}

export function getIntakeForm(formID) {
    return sendRequest(`${BASE_URL}/${formID}`)
}

export function updateForm(formID, data) {
    return sendRequest(`${BASE_URL}/updateForms/${formID}`, "PUT", data, "Unable to update form");
}

export function deleteForm(clientID, formID, service) {
    return sendRequest(`${BASE_URL}/${clientID}/${formID}/${service}`, "DELETE", null, "Unable to delete form");
}

export function generateProject(formID, data) {
    return sendRequest(`${BASE_URL}/generateProject/${formID}`, "POST", data, "Unable to generate project");
}

export function getProjectNumbers() {
    return sendRequest(`${BASE_URL}/getProjectNumbers`, "GET", null, "Unable to get project numbers")
}

export function updateNotes(data) {
    return sendRequest(`${BASE_URL}/${data.formID}/updateNotes`, "POST", data, "Unable to update notes")
}