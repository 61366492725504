import { useEffect, useState } from 'react'
import './ModifyIntakeForm2.css'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
import { formNotificationStyle2 } from '../../utilities/services/formNotification';
import { updateForm } from '../../utilities/api/allForms';
import IntakeFormNotes from '../IntakeFormNotes/IntakeFormNotes';

export default function ModifyIntakeForm2({form, services, updateIntakeForm}) {
    const date = new Date();
    const potentialInventorsQuantity = 5;
    const requiredPotentialInventors = 1;
    const inventiveElementQuantity = 4;
    const requiredFeatures = 2;
    const defaultFormData = {
        clientID: form.clientID,
        projectNumber: form.projectNumber,
        status: form.status,
        submissionDate: form.submissionDate || date.toISOString().split('T')[0],
        clientNumber: form.clientNumber,
        service: form.service,
        companyName: form.companyName,
        companyAddress: form.companyAddress,
        companyRepresentative: capitalizeFirstLetter(convertToString(form.companyRepresentative)),
        titleOfInvention: (form.titleOfInvention&& form.titleOfInvention) || '',
        inventor1: form.inventor1 || '',
        inventor2: form.inventor2 || '',
        inventor3: form.inventor3 || '',
        inventor4: form.inventor4 || '',
        inventor5: form.inventor5 || '',
        countryOfInterest: form.countryOfInterest || '',
        inventionDisclosure: form.inventionDisclosure || '',
        inventionDisclosureDescription: form.inventionDisclosureDescription || '',
        inventiveElement1: form.inventiveElement1 || '',
        inventiveElement2: form.inventiveElement2 || '',
        inventiveElement3: form.inventiveElement3 || '',
        inventiveElement4: form.inventiveElement4 || '',
        additionalInformation: form.additionalInformation || '',
        fileUploads: form.fileUploads || [],
    }
    const listOfCountries = ['US', 'Canada', 'EU', 'Australia', 'India', 'China']
    const [generalInformationComplete, setGeneralInformationComplete] = useState(true);
    const [renderInventionDisclosureDescription, setRenderInventionDisclosureDescription] = useState(true);
    const [technicalSection1Complete, setTechnicalSection1Complete] = useState(true);
    const [technicalSection2Complete, setTechnicalSection2Complete] = useState(true);// set to true since section is optional
    const tabCompletion = {
        0: generalInformationComplete,
        1: technicalSection1Complete,
        2: technicalSection2Complete,
    };
    const [disableAllFields] = useState(['complete'].includes(defaultFormData.status) ? true : false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData] = useState(new FormData());
    const tabs = ['generalInformation', 'technicalSection1', 'technicalSection2'];
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    function convertToString(array) {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const handleNextClick = async (evt) => {
        // const activeTab = document.getElementById(tabs[activeTabIndex]);
        const tabCompletionStatus = tabCompletion;
        if (!tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else if (activeTabIndex < tabs.length-1) {
            // activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(activeTabIndex + 1);
        }
    }

    const handleBackClick = (evt) => {
        if (activeTabIndex > 0) {
            setActiveTabIndex(activeTabIndex - 1);
        }
    }

    const handleTabClick = (index) => {
        const tabCompletionStatus = tabCompletion;
        // const activeTab = document.getElementById(tabs[activeTabIndex]);
        if (index > activeTabIndex && !tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else {
            // activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(index);
        }
    }

    const handleFormChange = (evt) => {
        if (evt.target.name === 'inventionDisclosure') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('inventionDisclosureDescription', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderInventionDisclosureDescription(false);
            } else {
                setRenderInventionDisclosureDescription(true);
            }
        } else {
            formData.set(evt.target.name, evt.target.value);
        }
        checkFormCompletion(formData);
        // console.log(formData)
    }

    const checkFormCompletion = (formData) => {
        const requiredFieldsByTab = {
            0: ['companyName', 'companyAddress', 'companyRepresentative', 'titleOfInvention', 'inventor1', 'countryOfInterest', 'inventionDisclosure', renderInventionDisclosureDescription && 'inventionDisclosureDescription'],
            1: ['inventiveElement1', 'inventiveElement2'],
            2: [],
        };
        const checkFormCompletion = (fields) => {
            let isComplete = true;
            for (var pair of formData.entries()) {
                if (fields.includes(pair[0]) && pair[1] === '') {
                    isComplete = false;
                }
            }
            return isComplete;
        };
        if (activeTabIndex in requiredFieldsByTab) {
            const isComplete = checkFormCompletion(requiredFieldsByTab[activeTabIndex]);
            if (activeTabIndex === 0) setGeneralInformationComplete(isComplete);
            if (activeTabIndex === 1) setTechnicalSection1Complete(isComplete);
            if (activeTabIndex === 2) setTechnicalSection2Complete(isComplete);
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        // console.log(Object.fromEntries(formData.entries()));
        setIsSubmitting(true);
        formData.set('status', 'changesSubmitted')
        const data = Object.fromEntries(formData.entries());
        try {
            const response = await updateForm(form._id, data);
            if (response.message === 'Form updated') {
                formNotificationStyle2('tabSwitchNotification', 'Changes successfully submitted!', '#0A7637', 0.8)
                setTimeout(() => {
                    // reload page
                    window.location.reload();
                }, 1500);
            } else {
                formNotificationStyle2('tabSwitchNotification', 'Changes could not be submitted!', '#CA150C', 0.8)
                console.log(response.message);                
            }
            setIsSubmitting(false);
        } catch (error) {
            setIsSubmitting(false);
            formNotificationStyle2('tabSwitchNotification', 'Changes could not be submitted!', '#CA150C', 0.8)
            console.log(error);
        }
    }

    useEffect(() => {
        setIsFormComplete(generalInformationComplete && technicalSection1Complete && technicalSection2Complete);
    },[generalInformationComplete, technicalSection1Complete, technicalSection2Complete])


    useEffect(() => {
        for (var pair of Object.entries(defaultFormData)) {
                formData.append(pair[0], pair[1]);
        }
        // console.log(Object.fromEntries(formData.entries()));
        if (defaultFormData.inventionDisclosure === 'N' || defaultFormData.inventionDisclosure === '') {
            setRenderInventionDisclosureDescription(false);
        } else {
            setRenderInventionDisclosureDescription(true);
        }
        // formData.append('fileUploads', [])
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        checkFormCompletion(formData);
        //eslint-disable-next-line
    }, [formData, defaultFormData])
  return (
        <div className='ModifyIntakeForm2 modifyIntakeFormGlobal'>
            <div className='upperSection'>
                <div className='info'>
                    <div className='firstSection'>
                        <div className='serviceTitle'>
                        {`${services.find(service => service.code === form.service).name}`}
                        </div>
                        <div className={`formStatus ${form.status}`}>
                            { ['inProgress', 'submitted', 'changesApproved'].includes(form.status) &&
                            <svg width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 0.96875C4.78854 0.96875 0.96875 4.78854 0.96875 9.5C0.96875 14.2115 4.78854 18.0312 9.5 18.0312C14.2115 18.0312 18.0312 14.2115 18.0312 9.5C18.0312 4.78854 14.2115 0.96875 9.5 0.96875ZM13.4375 10.8125H9.5C9.32595 10.8125 9.15903 10.7434 9.03596 10.6203C8.91289 10.4972 8.84375 10.3303 8.84375 10.1562V4.25C8.84375 4.07595 8.91289 3.90903 9.03596 3.78596C9.15903 3.66289 9.32595 3.59375 9.5 3.59375C9.67405 3.59375 9.84097 3.66289 9.96404 3.78596C10.0871 3.90903 10.1562 4.07595 10.1562 4.25V9.5H13.4375C13.6115 9.5 13.7785 9.56914 13.9015 9.69221C14.0246 9.81528 14.0938 9.9822 14.0938 10.1562C14.0938 10.3303 14.0246 10.4972 13.9015 10.6203C13.7785 10.7434 13.6115 10.8125 13.4375 10.8125Z" fill="#DFB200"/>
                            </svg>                
                            }
                            { ['complete'].includes(form.status) &&
                            <svg width="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 1.96875C5.79592 1.96875 1.96875 5.79592 1.96875 10.5C1.96875 15.2041 5.79592 19.0312 10.5 19.0312C15.2041 19.0312 19.0312 15.2041 19.0312 10.5C19.0312 5.79592 15.2041 1.96875 10.5 1.96875ZM14.9399 7.6408L9.42744 14.2033C9.36697 14.2753 9.29173 14.3335 9.20681 14.3739C9.12189 14.4143 9.02928 14.436 8.93525 14.4375H8.92418C8.8322 14.4375 8.74125 14.4181 8.65723 14.3806C8.57322 14.3432 8.49802 14.2885 8.4365 14.2201L6.074 11.5951C6.014 11.5315 5.96733 11.4565 5.93673 11.3746C5.90612 11.2926 5.89221 11.2054 5.89579 11.118C5.89938 11.0306 5.9204 10.9448 5.95762 10.8657C5.99483 10.7865 6.04749 10.7156 6.11251 10.6571C6.17752 10.5986 6.25357 10.5537 6.33619 10.525C6.41881 10.4963 6.50634 10.4844 6.59362 10.49C6.6809 10.4956 6.76618 10.5187 6.84444 10.5577C6.9227 10.5967 6.99237 10.651 7.04936 10.7174L8.90695 12.7813L13.9351 6.7967C14.0478 6.6663 14.2074 6.58553 14.3793 6.57183C14.5511 6.55814 14.7215 6.61262 14.8535 6.72351C14.9855 6.8344 15.0686 6.99279 15.0848 7.16444C15.1009 7.33608 15.0489 7.5072 14.9399 7.6408Z" fill="#158444"/>
                            </svg>                    
                            }
                            <span className='statusText'>
                            {
                            form.status === 'new' ? 'New' : form.status === 'inProgress' ? 'In Progress' : form.status === 'submitted' ? 'Submitted' : form.status === 'complete' ? 'Complete' : form.status === 'changesSubmitted' ? 'Changes Made' : form.status === 'changesApproved' ? 'Changes Approved' : ''
                            }
                            </span>
                        </div>
                    </div>
                    <div className='notesContainer'>
                        <button className='notesButton dropdown' id={`formNotes`} data-title={`Add & View Form Notes`} data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5F6B7A" className="bi bi-sticky-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z"/>
                            </svg>
                            <span>&nbsp;&nbsp;Notes</span>
                        </button>
                        <IntakeFormNotes formID={form._id} notes={form.notes} updateIntakeForm={updateIntakeForm} />
                    </div>
                </div>
                <div className="nav nav-pills flex-row navigation" id="v-pills-tab" role='tablist' aria-orientation="horizontal">
                {tabs.map((tab, index) => (
                    <button key={tab} onClick={() => handleTabClick(index)} className={`${tab} nav-link ${activeTabIndex === index ? 'active' : ''}`} id={`${tab}Tab`} type="button">
                        {tab === 'generalInformation' ? 'General Information' : tab === 'technicalSection1' ? 'Technical Section 1' : tab === 'technicalSection2' ? 'Technical Section 2' : 'Technical Section 3'}
                    </button>
                ))}
                </div>
                <div className='content tab-content' id="v-pills-tabContent">
                    <form className='' id='intakeForm2' formEncType='multipart/form-data' onSubmit={handleSubmit}>
                    {tabs.map((tab, index) => (
                        <div key={tab} className={`tab-pane fade ${activeTabIndex === index ? 'show active' : ''}`} id={tab} role="tabpanel" aria-labelledby={`${tab}Tab`} >
                            {tab === 'generalInformation' &&
                            <div className='generalInformation'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>General Information</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyName'>Legal name of client (company/person) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyName' name='companyName' defaultValue={defaultFormData.companyName} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyAddress'>Legal address of client <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyAddress' name='companyAddress' defaultValue={defaultFormData.companyAddress} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyRepresentative'>Full name of representative(s) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyRepresentative' name='companyRepresentative' defaultValue={defaultFormData.companyRepresentative} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='titleOfInvention'>Title of invention <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='titleOfInvention' name='titleOfInvention' defaultValue={defaultFormData.titleOfInvention} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>List of Potential Inventors</span>
                                    </div>
                                    <div className='sectionContent'>
                                    {[...Array(potentialInventorsQuantity)].map((element, index) => (
                                        <div key={index} className='mb-4'>
                                            <label className='form-label' htmlFor={`inventor${index+1}`}>Inventor {index+1} {index<requiredPotentialInventors && <span className='requiredField'><strong>*</strong></span>}</label>
                                            <input className='form-control' type='text' id={`inventor${index+1}`} name={`inventor${index+1}`} defaultValue={defaultFormData[`inventor${index+1}`]} onChange={handleFormChange} required={index<requiredPotentialInventors} disabled={disableAllFields} autoComplete='false' />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Country of Interest</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <label htmlFor="countryOfInterest" className=''>Country of interest <span className='requiredField'><strong>*</strong></span></label>
                                        <select className='form-select w-25' id='countryOfInterest' name='countryOfInterest' onChange={handleFormChange} defaultValue={defaultFormData.countryOfInterest} required disabled={disableAllFields}>
                                            <option value=''>Select Country</option>
                                            { listOfCountries.map((country, index) => (
                                                <option key={index} value={country}>{country}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Invention Disclosure</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Are these features related to a product already available in the market? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('inventionDisclosure') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="inventionDisclosureYes">
                                                    <input type="radio" name="inventionDisclosure" id="inventionDisclosureYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.inventionDisclosure === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('inventionDisclosure') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="inventionDisclosureNo">
                                                    <input type="radio" name="inventionDisclosure" id="inventionDisclosureNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.inventionDisclosure === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='inventionDisclosure' name='inventionDisclosure' onChange={handleFormChange} defaultValue={defaultFormData.inventionDisclosure} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    {(renderInventionDisclosureDescription) &&
                                    <div className='sectionContent'>
                                        <label className='form-label' htmlFor='inventionDisclosureDescription'>Please provide the product name <span className='requiredField'><strong>*</strong></span></label>
                                        <textarea className='form-control' id='inventionDisclosureDescription' rows={2} name='inventionDisclosureDescription' defaultValue={formData.get('inventionDisclosureDescription')} onChange={handleFormChange} disabled={disableAllFields} />
                                    </div>
                                    }
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection1' &&
                            <div className='technicalSection1'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Technical Section</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <ol className='smallFont'>
                                            <li>
                                                This Intake Form intends to collect the information needed for intellectual property research. It contains two main parts, as identified below. Riahi Patents will primarily use the information provided below as the input in the research, so <span  className='blueColorFont'>please include all the relevant information of your invention / product and fill out this form carefully</span>.
                                            </li>
                                            <li>
                                                If you have any doubts regarding this process, <span className='blueColorFont'>please reach out to Riahi Patents before delivering this intake form</span>. It may not be possible to add new information once the research has begun.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='smallFont italicBlueColorFont blueBack'>Please provide up to four main features of your invention / product in the following fields</div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Features & Elements</span>
                                    </div>
                                    <div className='smallFont sectionContent italicBlueColorFont'>
                                        <p className='strongFont mb-0'><i>Minimum: {requiredFeatures}</i></p>
                                        <p className='strongFont mb-0'><i>Maximum: {inventiveElementQuantity}</i></p>
                                    </div>
                                    <div className='sectionContent'>
                                    { [...Array(inventiveElementQuantity)].map((element, index) => (
                                        <div key={index} className='mb-4'>
                                            <label className='form-label' htmlFor={`inventiveElement${index+1}`}>Feature {index+1} {index<requiredFeatures && <span className='requiredField'><strong>*</strong></span>}</label>
                                            <textarea className='form-control' id={`inventiveElement${index+1}`} name={`inventiveElement${index+1}`} defaultValue={defaultFormData[`inventiveElement${index+1}`]} onChange={handleFormChange} required={index<requiredFeatures} disabled={disableAllFields} />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Additional Information</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <label className='form-label' htmlFor='additionalInformation'>Please provide any additional information that you consider relevant to the research</label>
                                        <textarea className='form-control' id='additionalInformation' name='additionalInformation' defaultValue={defaultFormData.additionalInformation} onChange={handleFormChange} disabled={disableAllFields} />
                                    </div>
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection2' &&
                            <div className='technicalSection2'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                                <rect width="21" height="21" transform="translate(1 2)" fill="white"/>
                                                <path d="M13.25 3.75H6.25C5.78587 3.75 5.34075 3.93437 5.01256 4.26256C4.68437 4.59075 4.5 5.03587 4.5 5.5V19.5C4.5 19.9641 4.68437 20.4092 5.01256 20.7374C5.34075 21.0656 5.78587 21.25 6.25 21.25H16.75C17.2141 21.25 17.6592 21.0656 17.9874 20.7374C18.3156 20.4092 18.5 19.9641 18.5 19.5V9L13.25 3.75Z" fill="#1A5E9B" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M13.25 3.75V9H18.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15 13.375H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15 16.875H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.75 9.875H8.875H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>Additional Documents</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='fileUploadContainer'>
                                            <div className='fileUploadContent'>
                                                { form.fileUploads.length>0 &&
                                                <div className='fileUploadPreviewContainer p-2'>
                                                    <div className=''>
                                                        { form.fileUploads.map((element, index) => (
                                                            <div key={index} className='fileUploadPreviewItem ps-1 mb-4'>
                                                                <a href={element} className='text-success'>{element}</a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                }
                                                { form.fileUploads[0]==='' &&
                                                <div className='fileUploadPreviewContainer p-2'>
                                                    <div className='text-success w-100 text-center'>No files uploaded</div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='smallFont italicBlueColorFont blueBack'>Please include any drafts, figures, drawings that your company considers being related to the invention / product under research that might help our research team. If you have documents in .pdf format, please upload them into the folder.</div>
                            </div>
                            }
                        </div>
                    ))}
                    </form>
                </div>
            </div>
            <div className='lowerSection'>
                <div className='tabSwitchNotificationContainer'>
                    <p id='tabSwitchNotification'className='tabSwitchNotification text-center'></p>
                </div>
                <div className='navigationButtons'>
                    <div className='backNext'>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='backButton' onClick={handleBackClick} disabled={activeTabIndex>0 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0249 17.725L1.7999 9.5L10.0249 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='nextButton' onClick={handleNextClick} disabled={activeTabIndex !== tabs.length-1 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.1499 17.725L9.3749 9.5L1.1499 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    { ['submitted', 'changesApproved', 'changesSubmitted'].includes(defaultFormData.status) &&
                    <>
                    <div type='button' id='cancelButton' className='cancelButton'>
                        <button className='navButton' data-bs-dismiss="modal">Cancel</button>
                    </div>
                    { activeTabIndex === tabs.length-1 &&
                    <div className='navigationButtonContainer'>
                        <button className='submitButton' type='submit' form='intakeForm2' id='submitButton' disabled={!isFormComplete || isSubmitting}>Submit Changes</button>
                    </div>
                    }
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
