import { useEffect, useState } from 'react'
import './IntakeForm2Review.css'

export default function IntakeForm2Review({form, services}) {
    const [inventors, setInventors] = useState([])
    const [elements, setElements] = useState([])
    const fallback = 'No information provided'

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const getListOfInventors = (form) => {
        const inventors = []
        let inventorCount = 1
        while (form[`inventor${inventorCount}`]) {
            inventors.push(form[`inventor${inventorCount}`])
            inventorCount++
        }
        setInventors(inventors)
    }

    const getListOfElements = (form) => {
        const elements = []
        let elementCount = 1
        while (form[`inventiveElement${elementCount}`]) {
            elements.push(form[`inventiveElement${elementCount}`])
            elementCount++
        }
        setElements(elements)
    }

    useEffect(() => {
        getListOfInventors(form)
        getListOfElements(form)
    }, [form])

  return (
    <div className='IntakeForm2Review intakeFormReviewGlobal'>
        <div className='projectNumber'>
            {`${form.projectNumber}`}
        </div>
        <div className='service'>
            {`${services.find(service => service.code === form.service).name}`}
        </div>
        <div className='titleOfInvention'>
            <span>Title of Invention: </span>
            <span>{`${form.titleOfInvention}`}</span>
        </div>
        <div className='submissionDate'>
            <span>Submission Date: </span>
            <span>{`${form.submissionDate? form.submissionDate.split('T')[0] : form.submissionDate}`}</span>
        </div>
        <div className='approvalDate'>
            { form.status === 'complete' &&
            <>
            <span>Approval Date: </span>
            <span>{form.approvalDate.split('T')[0] || 'N/A'}</span>
            </>
            }
        </div>
        <div className='generalInformation'>
            <div className='sectionTitle'>
                1. General Information
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    Legal name of Client
                </div>
                <div className='answer'>
                    {form.companyName}
                </div>
                <div className='questionL1'>
                    Legal address of Client
                </div>
                <div className='answer'>
                    {form.companyAddress}
                </div>
                <div className='questionL1'>
                    Client Representative(s)
                </div>
                <div className='answer'>
                    {convertToString(form.companyRepresentative)}
                </div>
                <div className='questionL1'>
                    Potential Inventor(s) & Address
                </div>
                <div className='answer'>
                    {convertToString(inventors)}
                </div>
            </div>
        </div>
        <div className='technical section'>
            <div className='sectionTitle'>
                2. Technical Section
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    2.1 Features & Elements
                </div>
                <div className='answer'>
                    {elements.map((element, idx) => (
                        <div key={idx} className='inventiveElements'>
                            <div><strong>Inventive Element {idx+1}:</strong>&nbsp;&nbsp;</div>
                            <div>{element}</div>
                        </div>
                    ))}
                </div>
                <div className='questionL1'>
                    2.2 Country of Interest
                </div>
                <div className='answer'>
                    {form.countryOfInterest}
                </div>
                <div className='questionL1'>
                    2.3 Invention Disclosure
                </div>
                <div className='questionL2'>
                    Are these features related to a product already available in the market?
                </div>
                <div className='answer'>
                    {form.inventionDisclosure==='Y' ? 'YES' : 'NO'}
                </div>
                { form.inventionDisclosure==='Y' &&
                <div className='answer'>
                    <div><strong>The product name is:</strong></div>
                    <ul>
                        <li>{form.inventionDisclosureDescription}</li>
                    </ul>
                </div>
                }
                <div className='questionL1'>
                    2.4 Additional Documents
                </div>
                <div className='answer'>
                    { (form.fileUploads[0]!=='' && form.fileUploads.length>0) ?
                    <>
                        <div>Links to additional documents uploaded by the client are provided below:</div>
                        <ul>
                            {form.fileUploads.map((file, idx) => (
                                <li key={idx}><a target='_blank' rel="noreferrer" href={file}>{file}</a></li>
                            ))}
                        </ul>
                    </>
                    :
                    'No additional documents provided'
                    }
                </div>
                <div className='questionL1'>
                    2.5 Additional Information
                </div>
                <div className='answer'>
                    {form.additionalInformation? form.additionalInformation : <span className='tertiaryText'><i>{fallback}</i></span>}
                </div>
            </div>
        </div>

    </div>
  )
}
