import './GenerateProject.css'
import NewProjectForm from '../NewProjectForm/NewProjectForm';

export default function GenerateProject({form, existingProjectNumbers, users}) {
  return (
    <div className='GenerateProject modal fade' id='newProjectModal' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`newProjectHeader`} aria-hidden="true">
      <NewProjectForm form={form} existingProjectNumbers={existingProjectNumbers} users={users} />
    </div>
  )
}
