import { useEffect, useState } from 'react'
import './FormGeneration.css'
import { create } from '../../utilities/api/allForms'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'

export default function FormGeneration({client, updateClientPage, updateIntakeForms, fullClientServices, getFullClientServices}) {
    const [formData, setFormData] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const handleChange = (evt) => {
        const newFormData = {
            ...formData,
            [evt.target.name]: evt.target.value
        }
        const filteredFormData = {}
        Object.keys(newFormData).forEach(key => {
            if (newFormData[key] > 0 && client.clientServices.includes(key)) {
                filteredFormData[key] = newFormData[key]
            }
        })
        setFormData(filteredFormData)
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        setSubmitting(true)
        try {
            const updatedClient = await create(client._id, formData)
            formNotificationStyle2('formNotification', 'Forms generated successfully!', 'green', 0.9)
            updateClientPage(updatedClient)
            updateIntakeForms(updatedClient._id)
            setFormData({})
            if (document.getElementById('generateForms')) document.getElementById('generateForms').reset()
        } catch (error) {
            formNotificationStyle2('formNotification', 'An error occurred!', 'red', 0.9)
            console.log(error)   
        }
        setSubmitting(false)
    }

    useEffect(() => {
        getFullClientServices()// Update client services when changed in the ClientServices tab
        // eslint-disable-next-line
    }, [client])

  return (
    <div className='FormGeneration'>
        { !submitting ?
        <>
        { fullClientServices.length>0 ?
        <form className='form' id='generateForms' onSubmit={handleSubmit}>
            <div className='formContent'>
            { fullClientServices.length>0 &&
            fullClientServices.map((service, idx) => (
                <div key={idx} className='form-group d-flex justify-content-between mb-2'>
                    <label className='form-label' htmlFor={`${service.code}FormGeneration`}>{`${service.name} (${service.code})`}</label>
                    <input className='form-control' type='number' min={0} name={`${service.code}`} id={`${service.code}FormGeneration`} defaultValue={ formData[`${service.code}`] || '0'} onChange={handleChange} />
                </div>
            ))
            }
            </div>
            <div className='submitButtonContainer'>
                <button className='btn btn-success' type='submit' disabled={Object.keys(formData).length===0}>Generate Forms</button>
                <div className='formNotification' id='formNotification'></div>
            </div>
        </form>
        :
        <>
        <h5 className='text-center pt-5 pb-3 text-danger'>No services selected</h5>
        <p className='text-center pb-5'><i>Please select a service from the previous tab</i></p>
        </>
        }
        </>
        :
        // <div className='loadingSpinner'>
        //     <div className='spinner-border' role='status'>
        //     </div>
        // </div>
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        }
    </div>
  )
}
