import {
    Document, TableRow, TableCell, Table, Paragraph, VerticalAlign, AlignmentType, WidthType,
    Header, TextRun, PageNumber, Footer, HeadingLevel, ExternalHyperlink, Packer, convertInchesToTwip, ShadingType
} from 'docx'

import {saveAs} from 'file-saver'
// import { capitalizeFirstLetter } from './capitalizeFirstLetter'

const convertToString = (array) => {
    // convert array to string separated by commas and spaces
    let string = ''
    array.forEach((item, idx) => {
        if (idx === array.length - 1) {
            string += item
        } else {
            string += item + ', '
        }
    })
    return string
}

const emptyFieldText = 'No information provided.'


const generalInformation = async (form, services) => {
    let elements = []
    let serviceName
    services.forEach(service => {
        if (service.code === form.service) {
            serviceName = service.name
        }
    })

    const service = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
        spacing: { before: 200, after: 500 },
        children: [
            new TextRun({
                text: `${serviceName}`,
                allCaps: true,
                italics: false,
            }),
        ]
    })
    elements.push(service)

    const submissionDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 200, after: 200 },
        children: [
            new TextRun({
                text: `Submission Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.submissionDate? form.submissionDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(submissionDate)

    const approvalDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 100, after: 500 },
        children: [
            new TextRun({
                text: `Approval Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.approvalDate? form.approvalDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(approvalDate)

    const firstHeader1 = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "1 General Information",
                allCaps: true,
            })
        ]
    })
    elements.push(firstHeader1)

    // Identification
    const firstHeader2 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "Identification",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstHeader2)

    const identificationTableRows = []

    const identificationTableFirstTableRow = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 1000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal name of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyName}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableFirstTableRow)

    const identificationTableSecondTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal address of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyAddress}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableSecondTableRow)

    const identificationTableThirdTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        //     fill: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Full name of company’s representative(s)`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${convertToString(form.companyRepresentative)}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableThirdTableRow)

    const identificationTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: identificationTableRows
    })
    elements.push(identificationTable)

    return elements
}

const technicalSection = (form) => {
    let elements = []
    const mainHeader = new Paragraph({
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "2 TECHNICAL SECTION",
                allCaps: true,
            })
        ]
    })
    elements.push(mainHeader)

    // 2.1 Patents
    const sec2p1Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "2.1 Patents ",
                // allCaps: true,
            }),
            form.companyPatents==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p1Header1)

    if (form.companyPatents === 'Y') {
        if (form.companyPatentItems.length > 0) {
            const sec2p1Statement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: `The company has the following patents:`,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(sec2p1Statement)
    
            const patentItems = form.companyPatentItems.map((item, idx) => {
                return new Paragraph({
                    style: 'MyNormalStyle',
                    alignment: AlignmentType.JUSTIFIED,
                    spacing: { before: 100, after: 100 },
                    bullet: {
                        level: 0
                    },
                    children: [
                        new TextRun({
                            text: `${item.description? item.description : emptyFieldText}`,
                        }),
                    ]
                })
            })
            elements.push(...patentItems)
            
        } else {
            const sec2p1Statement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: emptyFieldText,
                        italics: true,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(sec2p1Statement)
        }
    } else {
        const sec2p1Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any patents.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p1Statement)
    }

    // 2.2 Trademarks
    const sec2p2Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.2 Trademarks ",
                // allCaps: true,
            }),
            form.companyTrademarks==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p2Header1)

    if (form.companyTrademarks === 'Y') {
        if (form.companyTrademarkItems.length > 0) {
            const sec2p2Statement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: `The company has the following trademarks:`,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(sec2p2Statement)
        
            const trademarkItems = form.companyTrademarkItems.map((item, idx) => {
                return new Paragraph({
                    style: 'MyNormalStyle',
                    alignment: AlignmentType.JUSTIFIED,
                    spacing: { before: 100, after: 100 },
                    bullet: {
                        level: 0
                    },
                    children: [
                        new TextRun({
                            text: `${item.description? item.description : emptyFieldText}`,
                        }),
                    ]
                })
            })
            elements.push(...trademarkItems)
        } else {
            const sec2p2Statement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: emptyFieldText,
                        italics: true,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(sec2p2Statement)
        }
    } else {
        const sec2p2Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any trademarks.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p2Statement)
    }

    // 2.3 Trade Secrets
    const sec2p3Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.3 Trade Secrets ",
                // allCaps: true,
            }),
            form.companyTradeSecrets==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p3Header1)

    if (form.companyTradeSecrets === 'Y') {
        const tradeSecretsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please describe the trade secrets`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(tradeSecretsDescription1Question)

        const tradeSecretsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.companyTradeSecretsDescription1? form.companyTradeSecretsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(tradeSecretsDescription1)

        const tradeSecretsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `How long have you been using this data?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(tradeSecretsDescription2Question)

        const tradeSecretsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.companyTradeSecretsDescription2? form.companyTradeSecretsDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(tradeSecretsDescription2)
    } else {
        const sec2p3Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any trade secrets.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p3Statement)
    }

    // 2.4 Custom Software
    const sec2p4Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.4 Custom Software ",
                // allCaps: true,
            }),
            form.customSoftware==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p4Header1)

    if (form.customSoftware === 'Y') {
        const customSoftwareDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please describe the custom software.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(customSoftwareDescription1Question)

        const customSoftwareDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.customSoftwareDescription1? form.customSoftwareDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(customSoftwareDescription1)

        const customSoftwareDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `When was the first prototype software completed?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(customSoftwareDescription2Question)

        const customSoftwareDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.customSoftwareDescription2? form.customSoftwareDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(customSoftwareDescription2)

        const customSoftwareDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `Who developed it?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(customSoftwareDescription3Question)

        const customSoftwareDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.customSoftwareDescription3? form.customSoftwareDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(customSoftwareDescription3)
    } else {
        const sec2p4Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any custom software.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p4Statement)
    }

    // 2.5 Publications
    const sec2p5Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.5 Publications ",
                // allCaps: true,
            }),
            form.publications==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]   
    })
    elements.push(sec2p5Header1)

    if (form.publications === 'Y') {
        const publicationsTableHeaderRows = []
        if (form.publicationItems.length > 0) {
            const publicationsStatement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: `The company has the following publications:`,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(publicationsStatement)

            const publicationsTableHeaderRow = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "ECECEC",
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `Title`,
                                        bold: true,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "ECECEC",
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `Authors`,
                                        bold: true,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2000,
                            type: WidthType.DXA,
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "ECECEC",
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `Date`,
                                        bold: true,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 3000,
                            type: WidthType.DXA,
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "ECECEC",
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `Additional Info`,
                                        bold: true,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                ],
            })
            publicationsTableHeaderRows.push(publicationsTableHeaderRow)

            const publicationItems = form.publicationItems.map((item, idx) => {
                const publicationTableRow = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            margins: {
                                left: convertInchesToTwip(0.05),
                                right: convertInchesToTwip(0.05),
                                top: convertInchesToTwip(0),
                                bottom: convertInchesToTwip(0),
                            },
                            children: [
                                new Paragraph({
                                    style: 'MyTableStyle',
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${item.title? item.title : emptyFieldText}`,
                                        }),
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            margins: {
                                left: convertInchesToTwip(0.05),
                                right: convertInchesToTwip(0.05),
                                top: convertInchesToTwip(0),
                                bottom: convertInchesToTwip(0),
                            },
                            children: [
                                new Paragraph({
                                    style: 'MyTableStyle',
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${item.authors? item.authors : emptyFieldText}`,
                                        }),
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            margins: {
                                left: convertInchesToTwip(0.05),
                                right: convertInchesToTwip(0.05),
                                top: convertInchesToTwip(0),
                                bottom: convertInchesToTwip(0),
                            },
                            children: [
                                new Paragraph({
                                    style: 'MyTableStyle',
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${item.date? item.date : emptyFieldText}`,
                                        }),
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            margins: {
                                left: convertInchesToTwip(0.05),
                                right: convertInchesToTwip(0.05),
                                top: convertInchesToTwip(0),
                                bottom: convertInchesToTwip(0),
                            },
                            children: [
                                new Paragraph({
                                    style: 'MyTableStyle',
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${item.additionalInfo? item.additionalInfo : emptyFieldText}`,
                                        }),
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                    ],
                })
                return publicationTableRow
            })
            publicationsTableHeaderRows.push(...publicationItems)

            const publicationsTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: publicationsTableHeaderRows
            })
            elements.push(publicationsTable)
        } else {
            const sec2p5Statement = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: emptyFieldText,
                        // allCaps: true,
                    }),
                ]
            })
            elements.push(sec2p5Statement)
        }
    } else {
        const sec2p5Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any publications.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p5Statement)
    }

    // 2.6 Licenses
    const sec2p6Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.6 Licenses ",
                // allCaps: true,
            }),
            form.licenses==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p6Header1)

    if (form.licenses === 'Y') {
        const licensesDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details of the licenses.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(licensesDescription1Question)

        const licensesDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.licensesDescription1? form.licensesDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(licensesDescription1)

        const licensesDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `How long have you been using these software for?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(licensesDescription2Question)

        const licensesDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.licensesDescription2? form.licensesDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(licensesDescription2)

        const licensesDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `Name of the individuals in the team who maintain the list?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(licensesDescription3Question)

        const licensesDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.licensesDescription3? form.licensesDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(licensesDescription3)
    } else {
        const sec2p6Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any licenses.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p6Statement)
    }

    // 2.7 Company Brands
    const sec2p7Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.7 Company Brands ",
                // allCaps: true,
            }),
            form.companyBrands==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p7Header1)

    if (form.companyBrands === 'Y') {
        const companyBrandsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Where and when?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(companyBrandsDescription1Question)

        const companyBrandsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.companyBrandsDescription1? form.companyBrandsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(companyBrandsDescription1)

        const companyBrandsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `Owner`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(companyBrandsDescription2Question)

        const companyBrandsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.companyBrandsDescription2? form.companyBrandsDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(companyBrandsDescription2)
    } else {
        const sec2p7Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any company brands.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p7Statement)
    }

    // 2.8 Product Brands
    const sec2p8Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.8 Product Brands ",
                // allCaps: true,
            }),
            form.productBrands==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p8Header1)

    if (form.productBrands === 'Y') {
        const productBrandsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Where and when?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(productBrandsDescription1Question)

        const productBrandsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.productBrandsDescription1? form.productBrandsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(productBrandsDescription1)

        const productBrandsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `Owner`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(productBrandsDescription2Question)

        const productBrandsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.productBrandsDescription2? form.productBrandsDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(productBrandsDescription2)
    } else {
        const sec2p8Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any product brands.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p8Statement)
    }

    // 2.9 Marketing Materials
    const sec2p9Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.9 Marketing Materials ",
                // allCaps: true,
            }),
            form.marketingMaterials==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p9Header1)

    if (form.marketingMaterials === 'Y') {
        const marketingMaterialsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription1Question)

        const marketingMaterialsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.marketingMaterialsDescription1? form.marketingMaterialsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription1)

        const marketingMaterialsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `When the first Marketing/​Advertising Material was created?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription2Question)

        const marketingMaterialsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.marketingMaterialsDescription2? form.marketingMaterialsDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription2)

        const marketingMaterialsDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 250, after: 100 },
            children: [
                new TextRun({
                    text: `Owner.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription3Question)

        const marketingMaterialsDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.marketingMaterialsDescription3? form.marketingMaterialsDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(marketingMaterialsDescription3)
    } else {
        const sec2p9Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any marketing materials.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p9Statement)
    }
    
    // 2.10 Training Materials
    const sec2p10Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.10 Training Materials ",
                // allCaps: true,
            }),
            form.trainingMaterials==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p10Header1)

    if (form.trainingMaterials === 'Y') {
        const trainingMaterialsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription1Question)

        const trainingMaterialsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.trainingMaterialsDescription1? form.trainingMaterialsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription1)

        const trainingMaterialsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Estimated date When Training Material/​Product User Manuals was created?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription2Question)

        const trainingMaterialsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.trainingMaterialsDescription2? form.trainingMaterialsDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription2)

        const trainingMaterialsDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Who drafted those (in the company or outside)?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription3Question)

        const trainingMaterialsDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.trainingMaterialsDescription3? form.trainingMaterialsDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(trainingMaterialsDescription3)
    } else {
        const sec2p10Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any training materials.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p10Statement)
    }

    // 2.11 Domain Names
    const sec2p11Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.11 Domain Names ",
                // allCaps: true,
            }),
            form.domainNames==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p11Header1)

    if (form.domainNameItems.length>0) {
        const domainNamesTableHeaderRows = []
        const publicationsStatement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company has the following domains:`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(publicationsStatement)

        const domainNamesTableHeaderRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    shading: {
                        type: ShadingType.SOLID,
                        color: "ECECEC",
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Domain Name`,
                                    bold: true,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    shading: {
                        type: ShadingType.SOLID,
                        color: "ECECEC",
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Date of First Use`,
                                    bold: true,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    shading: {
                        type: ShadingType.SOLID,
                        color: "ECECEC",
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Expiry Date`,
                                    bold: true,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ],
        })
        domainNamesTableHeaderRows.push(domainNamesTableHeaderRow)

        const domainNames = form.domainNameItems.map((item, idx) => {
            const domainNamesTableRow = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 4000,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0.05),
                            right: convertInchesToTwip(0.05),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${item.domainName? item.domainName : emptyFieldText}`,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2000,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0.05),
                            right: convertInchesToTwip(0.05),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${item.dateOfFirstUse? item.dateOfFirstUse : emptyFieldText}`,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2000,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0.05),
                            right: convertInchesToTwip(0.05),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        children: [
                            new Paragraph({
                                style: 'MyTableStyle',
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${item.expiryDate? item.expiryDate : emptyFieldText}`,
                                    }),
                                ],
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                ],
            })
            return domainNamesTableRow
        })
        domainNamesTableHeaderRows.push(...domainNames)

        const domainNamesTable = new Table({
            alignment: AlignmentType.CENTER,
            rows: domainNamesTableHeaderRows
        })
        elements.push(domainNamesTable)
    } else {
        const sec2p11Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any domain names.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p11Statement)
    }

    // 2.12 Product designs
    const sec2p12Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.12 Product Designs ",
                // allCaps: true,
            }),
            form.productDesigns==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p12Header1)

    if (form.productDesigns === 'Y') {
        const productDesignsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(productDesignsDescription1Question)

        const productDesignsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.productDesignsDescription1? form.productDesignsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(productDesignsDescription1)
    } else {
        const sec2p12Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any product designs.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p12Statement)
    }

    // 2.13 Contracts
    const sec2p13Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.13 Contracts ",
                // allCaps: true,
            }),
            form.contracts==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p13Header1)

    if (form.contracts === 'Y') {
        const contractsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(contractsDescription1Question)

        const contractsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.contractsDescription1? form.contractsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(contractsDescription1)

        const contractsDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Do these agreements include IP clauses?`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(contractsDescription2Question)

        const contractsDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.contractsDescription2 === 'Y'? 'YES' : form.contractsDescription2 === 'N'? 'NO' : emptyFieldText}`,
                }),
            ]
        })
        elements.push(contractsDescription2)

        const contractsDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(contractsDescription3Question)

        const contractsDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.contractsDescription3? form.contractsDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(contractsDescription3)
    } else {
        const sec2p13Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any contracts.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p13Statement)
    }

    // 2.14 Departmental Standard Operating Procedures
    const sec2p14Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.14 Departmental Standard Operating Procedures ",
                // allCaps: true,
            }),
            form.departmentalStandardOperatingProcedures==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p14Header1)

    if (form.departmentalStandardOperatingProcedures === 'Y') {
        const departmentalStandardOperatingProceduresDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription1Question)

        const departmentalStandardOperatingProceduresDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.departmentalStandardOperatingProceduresDescription1? form.departmentalStandardOperatingProceduresDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription1)

        const departmentalStandardOperatingProceduresDescription2Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription2Question)

        const departmentalStandardOperatingProceduresDescription2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.departmentalStandardOperatingProceduresDescription2? form.departmentalStandardOperatingProceduresDescription2 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription2)

        const departmentalStandardOperatingProceduresDescription3Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription3Question)

        const departmentalStandardOperatingProceduresDescription3 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.departmentalStandardOperatingProceduresDescription3? form.departmentalStandardOperatingProceduresDescription3 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(departmentalStandardOperatingProceduresDescription3)
    } else {
        const sec2p14Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any departmental standard operating procedures.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p14Statement)
    }

    // 2.15 Certifications
    const sec2p15Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 600, after: 100 },
        children: [
            new TextRun({
                text: "2.15 Certifications ",
                // allCaps: true,
            }),
            form.certifications==='Y'?
            new TextRun({
                text: `(YES)`,
                bold: true,
                italics: true,
                // allCaps: true,
            })
            :
            new TextRun({
                text: `(NO)`,
                bold: true,
                italics: true,
                // allCaps: true,
            }),
        ]
    })
    elements.push(sec2p15Header1)

    if (form.certifications === 'Y') {
        const certificationsDescription1Question = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Please provide some details.`,
                    bold: true,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(certificationsDescription1Question)

        const certificationsDescription1 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `${form.certificationsDescription1? form.certificationsDescription1 : emptyFieldText}`,
                }),
            ]
        })
        elements.push(certificationsDescription1)
    } else {
        const sec2p15Statement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The company does not have any certifications.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(sec2p15Statement)
    }

    // 2.16 Additional Documents
    const additionalDocumentsHeader1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "2.16 Additional Documents",
                // allCaps: true,
            })
        ]
    })
    elements.push(additionalDocumentsHeader1)

    if (form.fileUploads.length>0 && form.fileUploads[0] !== '') {
        const additionalDocumentsStatement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 250 },
            children: [
                new TextRun({
                    text: `Links to additional documents uploaded by the client are provided below:`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(additionalDocumentsStatement)

        form.fileUploads.forEach(fileURL => {
            const additionalDocumentParagraph = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 200, after: 200 },
                bullet: {
                    level: 0
                },
                children: [
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${fileURL}`,
                                style: "Hyperlink",
                                size: 24,
                                font: 'arial',
                            }),
                        ],
                        link: `${fileURL}`,
                    }),
                ]
            })
            elements.push(additionalDocumentParagraph)
        });
    } else {
        const additionalDocumentsStatement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 250 },
            children: [
                new TextRun({
                    text: `No additional documents were uploaded.`,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(additionalDocumentsStatement)
    }

    return elements
}


async function saveDocumentToFile(doc, fileName, mimeType) {
    // Create a mime type that will associate the new file with Microsoft Word
    // const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then(blob => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
}

export const generateIntakeFormDocD = async (form, services) => {
    // console.log(data)
    const doc = new Document({
        creator: "Riahi-IP",
        description: `${form.projectNumber} - Intake Form`,
        title: `${form.titleOfInvention}`,
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        bold: true,
                        italics: false,
                        color: "000000",
                        font: "arial",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                        font: "arial",
                        // underline: {
                        //     type: UnderlineType.DOUBLE,
                        //     color: "000000",
                        // },
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                        font: "arial",
                    },
                },
                listParagraph: {
                    run: {
                        color: "000000",
                        font: "arial",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                    name: "My Spectacular Style",
                    basedOn: "Heading1",
                    next: "Heading1",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "990000",
                        font: "arial",
                        size: 24,
                    },
                },
                {
                    id: "MyNormalStyle",
                    name: "My Normal Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyTableStyle",
                    name: "My Table Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 20,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyItalicsStyle",
                    name: "My Italics Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                // 1 GENERAL INFORMATION
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: [PageNumber.CURRENT],
                                        font: 'arial',
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                spacing: {after: 400 },
                                children: [
                                    new TextRun({
                                        text: `${form.projectNumber}`,
                                        allCaps: true,
                                        font: "arial",
                                        size: 24,
                                        bold: false,
                                    }),
                                ]
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                border: {
                                    top: {
                                        color: 'auto',
                                        space: 1,
                                        style: "single",
                                        size: 10,
                                    }
                                },
                                children: [
                                    new TextRun({
                                        text: "Riahi Patents, Inc.",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new TextRun({
                                        text: "                                                                    ",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new ExternalHyperlink({
                                        children: [
                                            new TextRun({
                                                text: "https://riahipatents.com",
                                                style: "Hyperlink",
                                                size: 24,
                                                font: 'arial',
                                            }),
                                        ],
                                        link: "https://riahipatents.com",
                                    }),
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Confidential Document",
                                        size: 24,
                                        font: 'arial',
                                        italics: true,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                           
                        ],
                    }),
                },
                children: await generalInformation(form, services)
            },
            {
                // 2 TECHNICAL INTAKE FORM
                // properties: {
                //     type: SectionType.CONTINUOUS,
                // },
                children: technicalSection(form)
            },
        ],
    });
    const docTitle = (`${form.projectNumber.replaceAll('-','.')} - Intake Form (${form.service}).docx`)
    await saveDocumentToFile(doc, docTitle, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")

    // return Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(`${data.project.projNum}.docx`, buffer);
    // });
}