import './App.css';

import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

// Helpers
import { getUser } from '../../utilities/services/users';

// Components & Pages
import Login from '../Login/Login';
import NavBar from '../../components/NavBar/NavBar';
import ClientDetails from '../ClientDetails/ClientDetails';
import ClientList from '../ClientList/ClientList';
import IntakeFormReview from '../IntakeFormReview/IntakeFormReview';

export default function App() {
  const [user, setUser] = useState(getUser());

  // console.log(user)

  const updateUser = (user) => {
    setUser(user)
  }

  return (
    <main className="App">
      {
        !user ?
        <main className='body login'>
          <Routes>
            <Route path="/*" element={<Login updateUser={updateUser} user={user} />} />
          </Routes>
        </main>
        :
        <main className='body'>
          <div>
            <NavBar user={user} updateUser={updateUser} />
          </div>
          <div className='pages'>
            <Routes>
              <Route path="/" element={<ClientList user={user} />} />
              <Route path="/clients/:clientID" element={<ClientDetails user={user}  />} />
              <Route path='/reviewIntakeForms/:formID' element={<IntakeFormReview user={user} />} />
              <Route path='/*' element={<ClientList user={user} />} />
            </Routes>
          </div>
        </main>
      }
    </main>
  );
}
