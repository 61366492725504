import { useEffect, useState } from 'react'
import './FormList.css'
import FormListItem from '../FormListItem/FormListItem'

export default function FormList({client, loadingIntakeForms, updateIntakeForms, existingIntakeForms, services}) {
    const [sortOrder, setSortOrder] = useState('desc')
    const [sortedExistingIntakeForms, setSortedExistingIntakeForms] = useState(existingIntakeForms)
    const [sortHeader, setSortHeader] = useState('projectNumber')

    const handleHeaderClick = (evt) => {
        const header = evt.target.id
        setSortHeader(header)
        let sortedExistingIntakeForms = [...existingIntakeForms]
        if (sortOrder === 'asc') {
            sortedExistingIntakeForms.sort((a, b) => (typeof(a[header])==='string' ? a[header].toLowerCase() : a[header]) < (typeof(b[header])==='string' ? b[header].toLowerCase() : b[header]) ? 1 : -1)
            setSortOrder('desc')
        } else {
            sortedExistingIntakeForms.sort((a, b) => (typeof(a[header])==='string' ? a[header].toLowerCase() : a[header]) > (typeof(b[header])==='string' ? b[header].toLowerCase() : b[header]) ? 1 : -1)
            setSortOrder('asc')
        }
        setSortedExistingIntakeForms(sortedExistingIntakeForms)
    }

    useEffect(() => {
        updateIntakeForms(client._id)
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        setSortedExistingIntakeForms(existingIntakeForms)
    },[existingIntakeForms])

  return (
    <div className='FormList'>
        { loadingIntakeForms ?
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        { sortedExistingIntakeForms.length>0 ?
            <div className='formListContainer'>
                <div className='formListTitle'>Client Forms</div>
                <div className='formListTableContainer'>
                    <table className='table table-hover'>
                        <thead className=''>
                            <tr>
                                <th id='projectNumber' onClick={handleHeaderClick} title='Click to sort by project number'>
                                    <div className='tableHeaderWithArrow'><div>Project Number&nbsp;&nbsp;</div>
                                    { sortHeader==='projectNumber'?
                                        <>
                                        {sortOrder==='asc' ?
                                        <div>
                                            <div className='sortIndicatorActive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                        : 
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorActive'>&#9660;</div>
                                        </div>
                                        }
                                        </>
                                        :
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                    }
                                    </div>
                                </th>
                                <th id='service' onClick={handleHeaderClick} title='Click to sort by service'>
                                    <div className='tableHeaderWithArrow'><div>Service&nbsp;&nbsp;</div>
                                    { sortHeader==='service'?
                                        <>
                                        {sortOrder==='asc' ?
                                        <div>
                                            <div className='sortIndicatorActive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                        : 
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorActive'>&#9660;</div>
                                        </div>
                                        }
                                        </>
                                        :
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                    }
                                    </div>
                                </th>
                                <th id='createdAt' onClick={handleHeaderClick} title='Click to sort by creation date'>
                                    <div className='tableHeaderWithArrow'><div>Creation Date&nbsp;&nbsp;</div>
                                    { sortHeader==='createdAt'?
                                        <>
                                        {sortOrder==='asc' ?
                                        <div>
                                            <div className='sortIndicatorActive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                        : 
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorActive'>&#9660;</div>
                                        </div>
                                        }
                                        </>
                                        :
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                    }
                                    </div>
                                </th>
                                <th id='status' onClick={handleHeaderClick} title='Click to sort by status'>
                                    <div className='tableHeaderWithArrow'><div>Status&nbsp;&nbsp;</div>
                                    { sortHeader==='status'?
                                        <>
                                        {sortOrder==='desc' ?
                                        <div>
                                            <div className='sortIndicatorActive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                        : 
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorActive'>&#9660;</div>
                                        </div>
                                        }
                                        </>
                                        :
                                        <div>
                                            <div className='sortIndicatorInactive'>&#9650;</div>
                                            <div className='sortIndicatorInactive'>&#9660;</div>
                                        </div>
                                    }
                                    </div>
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className='formItem'>
                            { sortedExistingIntakeForms.map((form, idx) => (
                                <FormListItem key={idx} form={form} client={client} updateIntakeForms={updateIntakeForms} services={services} />
                            ))
                            }
                        </tbody>
                    </table>
                </div>
                
            </div>
            :
            <div className='emptyList'>
                <div className='text-center text-danger mt-5'>
                    <h5>No Forms Created</h5>
                </div>
            </div>
        }
        </>
        }
    </div>
  )
}
