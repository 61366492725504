import { updateClientServices } from '../../utilities/api/clients'
import './ClientServicesItem.css'

export default function ClientServicesItem({service, client, selectedForClient, updateClientPage}) {

    const handleCheckboxChange = async (evt) => {
        const updatedClient = await updateClientServices(client._id, service)
        // console.log(updatedClient)
        updateClientPage(updatedClient)
    }
  return (
    <div className={`ClientServicesItem checked${selectedForClient}`} title={service.description} onChange={handleCheckboxChange}>
        
        <label htmlFor={`${service.code}Service`}>
          <div className='inputContainer'>
            <input type="checkbox" defaultChecked={selectedForClient} id={`${service.code}Service`} name={`${service.code}Service`} value={service.code} />
            <span>{`${service.name}`}</span>
          </div>
          <div className='serviceCode'>
            <span>{`(${service.code})`}</span>
          </div>
        </label>
    </div>
  )
}
