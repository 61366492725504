import './IntakeForm4Review.css'

export default function IntakeForm4Review({form, services}) {
    const firstUpdateDate = '2024-07-04';

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

  return (
    <div className='IntakeForm4Review intakeFormReviewGlobal'>
        <div className='projectNumber'>
            {`${form.projectNumber}`}
        </div>
        <div className='service'>
            {`${services.find(service => service.code === form.service).name}`}
        </div>
        <div className='submissionDate'>
            <span>Submission Date: </span>
            <span>{`${form.submissionDate? form.submissionDate.split('T')[0] : form.submissionDate}`}</span>
        </div>
        <div className='approvalDate'>
            { form.status === 'complete' &&
            <>
            <span>Approval Date: </span>
            <span>{form.approvalDate.split('T')[0] || 'N/A'}</span>
            </>
            }
        </div>
        <div className='generalInformation'>
            <div className='sectionTitle'>
                1. General Information
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    Legal name of Client
                </div>
                <div className='answer'>
                    {form.companyName}
                </div>
                <div className='questionL1'>
                    Legal address of Client
                </div>
                <div className='answer'>
                    {form.companyAddress}
                </div>
                <div className='questionL1'>
                    Client Representative(s)
                </div>
                <div className='answer'>
                    {convertToString(form.companyRepresentative)}
                </div>
                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                <>
                <div className='questionL1'>
                    Associated Technology / Industry
                </div>
                <div className='answer'>
                    {form.industry}
                </div>
                </>
                }
            </div>
        </div>
        <div className='technical section'>
            <div className='sectionTitle'>
                2. Technical Section
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    2.1 Patents <i>({form.companyPatents==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.companyPatents==='Y' ?
                <div className='answer'>
                    {form.companyPatentItems.map((patent, idx) => (
                        <div key={idx} className='patents'>
                            <div><strong>Patent {idx+1}:</strong>&nbsp;&nbsp;</div>
                            <div>{patent.description}</div>
                        </div>
                    ))}
                </div>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.2 Trademarks <i>({form.companyTrademarks==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.companyTrademarks==='Y' ?
                <div className='answer'>
                    {form.companyTrademarkItems.map((trademark, idx) => (
                        <div key={idx} className='patents'>
                            <div><strong>Trademark {idx+1}:</strong>&nbsp;&nbsp;</div>
                            <div>{trademark.description}</div>
                        </div>
                    ))}
                </div>
                :
                <div className='answer'>
                    NO
                </div>
                }
                
                <div className='questionL1'>
                    2.3 Trade Secrets <i>({form.companyTradeSecrets==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.companyTradeSecrets==='Y' ?
                <>
                <div className='questionL2'>
                    Please describe the trade secrets
                </div>
                <div className='answer'>
                    <div>{form.companyTradeSecretsDescription1}</div>
                </div>
                <div className='questionL2'>
                    How long have you been using this data?
                </div>
                <div className='answer'>
                    <div>{form.companyTradeSecretsDescription2}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                <>
                <div className='questionL1'>
                    2.4 Unfiled Intellectual Property (IP) <i>({form.unfiledIP==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.unfiledIP==='Y' ?
                <div className='answer'>
                    <table className='table table-striped table-bordered'>
                        <thead className='table-dark'>
                            <tr>
                                <th>IP Type</th>
                                <th>Ownership</th>
                                <th>Date of First Use</th>
                            </tr>
                        </thead>
                        <tbody>
                            {form.unfiledIPItems.map((item, idx) => (
                                <tr key={idx}>
                                    <td>{item.ipType}</td>
                                    <td>{item.ownership}</td>
                                    <td>{item.dateOfFirstUse}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                <div className='answer'>
                    NO
                </div>
                }
                </>
                }
                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                <>
                <div className='questionL1'>
                    2.5 Concerns with Infringement <i>({form.concernsWithInfringement==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.concernsWithInfringement==='Y' ?
                <>
                <div className='questionL2'>
                    Please give some details
                </div>
                <div className='answer'>
                    <div>{form.concernsWithInfringementDescription1}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                </>
                }
                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                <>
                <div className='questionL1'>
                    2.6 Known Competitors <i>({form.knownCompetitors==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.knownCompetitors==='Y' ?
                <>
                <div className='questionL2'>
                    Please give some details
                </div>
                <div className='answer'>
                    <div>{form.knownCompetitorsDescription1}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                </>
                }
                <div className='questionL1'>
                    2.7 Custom Software <i>({form.customSoftware==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.customSoftware==='Y' ?
                <>
                <div className='questionL2'>
                    Please describe the custom software
                </div>
                <div className='answer'>
                    <div>{form.customSoftwareDescription1}</div>
                </div>
                <div className='questionL2'>
                    When was the first prototype software completed?
                </div>
                <div className='answer'>
                    <div>{form.customSoftwareDescription2}</div>
                </div>
                <div className='questionL2'>
                    Who developed it?
                </div>
                <div className='answer'>
                    <div>{form.customSoftwareDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.8 Publications <i>({form.publications==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.publications==='Y' ?
                <div className='answer'>
                    <table className='table table-striped table-bordered'>
                        <thead className='table-dark'>
                            <tr>
                                <th>Title</th>
                                <th>Authors</th>
                                <th>Date</th>
                                <th>Additional Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {form.publicationItems.map((publication, idx) => (
                                <tr key={idx}>
                                    <td>{publication.title}</td>
                                    <td>{publication.authors}</td>
                                    <td>{publication.date}</td>
                                    <td>{publication.additionalInfo || ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.9 Licenses <i>({form.licenses==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.licenses==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details of the licenses
                </div>
                <div className='answer'>
                    <div>{form.licensesDescription1}</div>
                </div>
                <div className='questionL2'>
                    How long have you been using these software for?
                </div>
                <div className='answer'>
                    <div>{form.licensesDescription2}</div>
                </div>
                <div className='questionL2'>
                    Name the individuals in the team who maintain the list
                </div>
                <div className='answer'>
                    <div>{form.licensesDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.10 Company Brands <i>({form.companyBrands==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.companyBrands==='Y' ?
                <>
                <div className='questionL2'>
                    Where and when?
                </div>
                <div className='answer'>
                    <div>{form.companyBrandsDescription1}</div>
                </div>
                <div className='questionL2'>
                    Owner
                </div>
                <div className='answer'>
                    <div>{form.companyBrandsDescription2}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.11 Product Brands <i>({form.productBrands==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.productBrands==='Y' ?
                <>
                <div className='questionL2'>
                    Where and when?
                </div>
                <div className='answer'>
                    <div>{form.productBrandsDescription1}</div>
                </div>
                <div className='questionL2'>
                    Owner
                </div>
                <div className='answer'>
                    <div>{form.productBrandsDescription2}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.12 Marketing Materials <i>({form.marketingMaterials==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.marketingMaterials==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.marketingMaterialsDescription1}</div>
                </div>
                <div className='questionL2'>
                    When was the first Marketing/Advertising Material created?
                </div>
                <div className='answer'>
                    <div>{form.marketingMaterialsDescription2}</div>
                </div>
                <div className='questionL2'>
                    Owner
                </div>
                <div className='answer'>
                    <div>{form.marketingMaterialsDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.13 Training Materials <i>({form.trainingMaterials==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.trainingMaterials==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.trainingMaterialsDescription1}</div>
                </div>
                <div className='questionL2'>
                    Estimated date when Training Material/Product User Manual was created
                </div>
                <div className='answer'>
                    <div>{form.trainingMaterialsDescription2}</div>
                </div>
                <div className='questionL2'>
                Who drafted those (in the company or outside)? 
                </div>
                <div className='answer'>
                    <div>{form.trainingMaterialsDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.14 Domain Names <i>({form.domainNameItems.length>0 ? 'YES' : 'NO'})</i>
                </div>
                { form.domainNameItems.length>0 ?
                <div className='answer'>
                    <table className='table table-striped table-bordered'>
                        <thead className='table-dark'>
                            <tr>
                                <th>Domain Name</th>
                                <th>First Use</th>
                                <th>Expiration Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {form.domainNameItems.map((domain, idx) => (
                                <tr key={idx}>
                                    <td>{domain.domainName}</td>
                                    <td>{domain.dateOfFirstUse}</td>
                                    <td>{domain.expiryDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                <div className='answer'>
                    NONE PROVIDED
                </div>
                }
                <div className='questionL1'>
                    2.15 Product Designs <i>({form.productDesigns==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.productDesigns==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.productDesignsDescription1}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.16 Contracts <i>({form.contracts==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.contracts==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.contractsDescription1}</div>
                </div>
                <div className='questionL2'>
                    Do these agreements include IP clauses?
                </div>
                <div className='answer'>
                    <div>{form.contractsDescription2==='Y' ? 'YES' : 'NO'}</div>
                </div>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.contractsDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.17 Departmental Standard Operating Procedures <i>({form.departmentalStandardOperatingProcedures==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.departmentalStandardOperatingProcedures==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.departmentalStandardOperatingProceduresDescription1}</div>
                </div>
                <div className='questionL2'>
                    How old are these SOPs?
                </div>
                <div className='answer'>
                    <div>{form.departmentalStandardOperatingProceduresDescription2}</div>
                </div>
                <div className='questionL2'>
                    Who authored/​maintains (team) these?
                </div>
                <div className='answer'>
                    <div>{form.departmentalStandardOperatingProceduresDescription3}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.18 Certifications <i>({form.certifications==='Y' ? 'YES' : 'NO'})</i>
                </div>
                { form.certifications==='Y' ?
                <>
                <div className='questionL2'>
                    Please provide some details
                </div>
                <div className='answer'>
                    <div>{form.certificationsDescription1}</div>
                </div>
                </>
                :
                <div className='answer'>
                    NO
                </div>
                }
                <div className='questionL1'>
                    2.19 Additional Documents
                </div>
                <div className='answer'>
                    { (form.fileUploads[0]!=='' && form.fileUploads.length>0) ?
                    <>
                        <div>Links to additional documents uploaded by the client are provided below:</div>
                        <ul>
                            {form.fileUploads.map((file, idx) => (
                                <li key={idx}><a target='_blank' rel="noreferrer" href={file}>{file}</a></li>
                            ))}
                        </ul>
                    </>
                    :
                    'No additional documents provided'
                    }
                </div>
                
            </div>
        </div>

    </div>
  )
}
