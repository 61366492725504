import './Login.css'
import LoginForm from "../../components/LoginForm/LoginForm";
// import { useNavigate } from "react-router-dom";
// import { useEffect } from 'react';

export default function Login({ updateUser, user }) {
  
  return (
    <div className="Login">
      <div className='formContainer'>
        <LoginForm updateUser={updateUser}/>
      </div>
    </ div>
  );
}